<template>
    <a-modal v-model="goodsVisible" :width="620" @cancel="goodsDetailCancel" :footer="null">
        <div>
            <div class="title">
                商品详情
                <img v-if="currentGoodsDetail.type == 1" src="https://qiniu.youjiamall.cn/crm-danpin.png" alt="">
                <img v-if="currentGoodsDetail.type == 2" style="width: 55px;"
                    src="https://qiniu.youjiamall.cn/crm-zuhepin.png" alt="">
            </div>
            <div class="swiper" v-if="currentGoodsDetail.type == 1">
                <img v-if="currentGoodsDetail.newGoodsSpecVoList" :src="currentGoodsDetail.newGoodsSpecVoList[0].skuList[currentSpecIndex].goodsSpecImagePath" alt="">
                <img v-else :src="currentGoodsDetail.goodsSpecVoList[0].goodsSpecImagePath" alt="">
            </div>

            <div class="spu-tit" v-if="currentGoodsDetail.type == 2">
                <p>{{ currentGoodsDetail.name }}</p>
                <div style="display:flex;align-items:baseline">
                    <span style="font-size: 16px">¥</span>
                    <span v-if="currentGoodsDetail.price">{{ currentGoodsDetail.price.toFixed(2) }}</span>
                </div>
            </div>

            <!-- 单品 -->
            <div v-if="currentGoodsDetail.type == 1">
                <div class="goods-box" v-for="(item, index) in currentGoodsDetail.newGoodsSpecVoList" :key="index">
                    <p class="goods-name"><span style="text-decoration: underline;">【{{
        item.skuList[currentSpecIndex].supplierId }}】</span>{{ item.goodsName }}</p>
                    <div class="prog-ls-top items-ls-top">
                        <p style="display: flex;align-items:center;">
                            <a @click="openH(item.skuList[currentSpecIndex].thirdUrl)" target="_blank"
                                v-if="item.skuList[currentSpecIndex].thirdUrl">H</a>
                            <a-popover>
                                <template slot="content">
                                    7天无理由退换货
                                </template>
                                <a style="cursor: default;" href="javascript:;"
                                    v-if="item.skuList[currentSpecIndex].returnable == 1">7</a>
                            </a-popover>

                            <a-popover>
                                <template slot="content">
                                    有质检报告
                                </template>
                                <a style="cursor: default;" href="javascript:;"
                                    v-if="item.skuList[currentSpecIndex].isQirPic == 1">质</a>
                            </a-popover>
                        </p>
                    </div>

                    <div class="goods-price">
                        <p><span style="color:red;margin-right: 0;">¥</span>{{ item.skuList[currentSpecIndex].price ? item.skuList[currentSpecIndex].price.toFixed(2):'0.00' }}
                        </p>
                        <span>{{ item.skuList[currentSpecIndex].threeTypeName }}:¥{{
        item.skuList[currentSpecIndex].marketPrice.toFixed(2)
    }}</span>
                        <span>市场价:¥{{ item.skuList[currentSpecIndex].plfPrice.toFixed(2) }}</span>
                    </div>
                    <div class="goods-spec">
                        <p class="goods-spec-left">规格</p>
                        <div>
                            <p style="color:red;" v-if="currentGoodsDetail.type == 1"><a-icon type="exclamation-circle"
                                    style="margin-right:5px;" />如当前产品有多个规格，用户可选择下单</p>
                            <ul class="goods-spec-list" style="margin-top:10px;">
                                <li style="cursor: pointer" v-for="(spec, specIndex) in item.skuList" :key="specIndex"
                                    @click="specCheck(index, specIndex)"
                                    :class="{ 'currentActive': currentSpecIndex == specIndex }">{{
        spec.specsAttributeValue }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 组合品 -->
            <div v-if="currentGoodsDetail.type == 2">
                <div class="goods-box" v-for="(item, index) in currentGoodsDetail.newGoodsSpecVoList" :key="index">
                    <div class="goods-top" :class="{ 'comActive': currentComIndex == index }" @click="checkCom(index)">
                        <div class="goods-img">
                            <img :src="item.skuList[item.skuIdx].goodsSpecImagePath" alt="">
                            <span class="goods-count">x{{ item.skuList[item.skuIdx].goodsSpecCount }}</span>
                        </div>
                        <p class="goods-name">
                            <span style="text-decoration: underline;">
                                【{{ item.skuList[item.skuIdx].supplierId }}】
                            </span>
                            {{ item.goodsName }}
                        </p>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: space-between;margin: 7px 0;">
                        <div class="prog-ls-top items-ls-top">
                            <p style="display: flex;align-items:center;">
                                <a @click="openH(item.skuList[item.skuIdx].thirdUrl)" target="_blank"
                                    v-if="item.skuList[item.skuIdx].thirdUrl">H</a>
                                <a-popover>
                                    <template slot="content">
                                        7天无理由退换货
                                    </template>
                                    <a style="cursor: default;" href="javascript:;"
                                        v-if="item.skuList[item.skuIdx].returnable == 1">7</a>
                                </a-popover>

                                <a-popover>
                                    <template slot="content">
                                        有质检报告
                                    </template>
                                    <a style="cursor: default;" href="javascript:;"
                                        v-if="item.skuList[item.skuIdx].isQirPic == 1">质</a>
                                </a-popover>
                            </p>

                        </div>

                        <div class="goods-price">
                            <span>{{ item.skuList[item.skuIdx].threeTypeName }}:¥{{
        item.skuList[item.skuIdx].marketPrice.toFixed(2) }}</span>
                            <span>市场价:¥{{ item.skuList[item.skuIdx].plfPrice.toFixed(2) }}</span>
                        </div>
                    </div>

                    <div class="goods-spec">
                        <p class="goods-spec-left" style="padding-top:10px;">规格</p>
                        <div>
                            <p style="color:red;" v-if="currentGoodsDetail.type == 1"><a-icon type="exclamation-circle"
                                    style="margin-right:5px;" />如当前产品有多个规格，用户可选择下单</p>
                            <ul class="goods-spec-list">
                                <li style="cursor: pointer" v-for="(spec, specIndex) in item.skuList" :key="specIndex"
                                    @click="specCheckCom(index, specIndex)"
                                    :class="{ 'currentActive': specIndex == item.skuIdx }">
                                    {{ spec.specsAttributeValue }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div class="pub-imgs-box">
                <div class="pub-imgs-top">
                    <p @click="current = 0" :class="{ 'pub-imgs-active': current == 0 }">商品介绍</p>
                    <p @click="current = 1" :class="{ 'pub-imgs-active': current == 1 }">质检信息</p>
                </div>
                <div class="pub-imgs-main">
                    <div v-if="current == 0">
                        <img v-for="(imgs, imgIdx) in goodsImgs.detailPicList" :key="imgIdx" :src="imgs" alt="">
                    </div>
                    <div v-if="current == 0 && goodsImgs.detailPicList.length == 0">
                        <a-empty></a-empty>
                    </div>
                    <div v-if="current == 1">
                        <embed width="100%" height="600" v-for="(imgs, imgIdx) in goodsImgs.qualificationFileList" :key="imgIdx" :src="imgs" alt="">
                    </div>
                    <div v-if="current == 1 && goodsImgs.qualificationFileList.length == 0">
                        <a-empty></a-empty>
                    </div>
                </div>
            </div>
        </div>
    </a-modal>
</template>
<script>
import { request } from '@/utils/request'
export default {
    props: {
        goodsVisible: {
            type: Boolean,
            default: false
        },
        goodsDetailId: {
            type: String,
            default: ''
        },
        currentGoodsDetail: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            goodsImgs: {
                detailPicList: [],
                qualificationFileList: []
            },
            currentSpecIndex: 0,
            currentIndex: 0,
            current: 0,//单品
            currentComIndex: 0,//组合品
        }
    },
    watch: {
        goodsDetailId(val) {
            this.currentSpecIndex = 0
            if (!val) return;
            this.current = 0;
            this.currentComIndex = 0;
            this.goodsImgs = {
                detailPicList: [],
                qualificationFileList: []
            }
            if (this.currentGoodsDetail.type == 1) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/goodsDetail/' + val, 'post', {}).then(res => {
                    if (res.data.code == 200) {
                        this.goodsImgs = res.data.data;
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            } else {

                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/goodsDetail/' + this.currentGoodsDetail.newGoodsSpecVoList[this.currentComIndex].goodsId, 'post', {}).then(res => {
                    if (res.data.code == 200) {
                        this.goodsImgs = res.data.data;
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
                this.currentGoodsDetail.newGoodsSpecVoList.forEach(item => {
                    // item.skuList.forEach((it, idx) => {
                    //     it.idx = idx;
                    //     it.currentIdx = 0;
                    // })
                    item.skuIdx = 0;
                    console.log(item,'item')
                    // item.skuList.forEach(it => {
                    //     it.idx = 0;
                    // })
                })
            }
            console.log(this.goodsImgs, 'this.goodsImgs')
        }
    },
    mounted() {
        
    },
    methods: {
        checkCom(index) {
            this.currentComIndex = index;
            // currentGoodsDetail.newGoodsSpecVoList
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/goodsDetail/' + this.currentGoodsDetail.newGoodsSpecVoList[index].goodsId, 'post', {}).then(res => {
                if (res.data.code == 200) {
                    this.goodsImgs = res.data.data;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        openH(url) {
            window.open(url)
        },
        specCheck(index, specIndex) {
            console.log(index, specIndex, 'index, specIndex')
            console.log(this.currentGoodsDetail.newGoodsSpecVoList[index].skuList[specIndex].thirdUrl, 'this.currentGoodsDetail.newGoodsSpecVoList[index].skuList[specIndex]')
            this.currentSpecIndex = specIndex;
            this.currentIndex = index;
        },
        specCheckCom(index, specIndex) {
            console.log(index, specIndex, 'index, specIndex')
            this.$set(this.currentGoodsDetail.newGoodsSpecVoList[index], 'skuIdx', specIndex);
            this.$forceUpdate()
        },
        goodsDetailCancel() {
            this.$emit('goodsDetailCancel')
        }
    }
}
</script>
<style scoped>
.comActive {
    border: 2px solid #5542F6 !important;
}

.goods-top {
    border: 1px solid #EAEAEA;
    padding: 6px;
    display: flex;
    border-radius: 4px;
    cursor: pointer;
}

.goods-count {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    height: 18px;
    line-height: 18px;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
}

.goods-img {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin-right: 8px;
}

.goods-img img {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    object-fit: contain;
}

/* 图片资质 */
.pub-imgs-top {
    width: 100%;
    background: #ECECEC;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.pub-imgs-top p {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
}

.pub-imgs-top .pub-imgs-active {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
    background: #5542F6;
}

.pub-imgs-main {
    width: 100%;
    padding: 10px 0 20px;
}

.pub-imgs-main img {
    width: 100%;
}

.pub-imgs-box {
    padding: 0 20px;
}

/* 图片资质end */
.currentActive {
    border: 2px solid #5542F6 !important;
}


/* // spu弹窗 */
.spu-tit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 17px;
    color: #000000;
    padding: 20px 20px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #E7E7E7;
    margin-bottom: 20px;
}

.spu-tit span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #FF0000;
}

.goods-spec-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    /* margin-top: 10px; */
}

.goods-spec-list li {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ABABAB;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #000000;
    margin-right: 12px;
    margin-bottom: 12px;
}

.goods-spec-list .goods-spec-active {
    border: 2px solid #5542F6;
}

p {
    padding: 0;
    margin: 0;
}

.goods-box {
    padding: 0 20px;
}

.swiper {
    width: 100%;
    height: 520px;
    margin-bottom:10px;
}

.swiper img {
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: contain;
}

.goods-spec {
    display: flex;
}

.goods-spec-left {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    margin-right: 14px;
    width:30px;
    padding-top: 1px;
}

.goods-price ul {
    display: flex;
    flex-wrap: wrap;
}

.goods-price li {
    margin-right: 12px;
    margin-bottom: 12px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #ABABAB;
}

.goods-price {
    display: flex;
    align-items: baseline;
}

.goods-price p {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #FF0000;
    margin-right: 16px;
}

.goods-price span {
    font-family: PingFangSC, PingFang SC;
    /* font-weight: 500; */
    font-size: 15px;
    color: #747474;
    margin-right: 16px;
}

.title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #0D0A19;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
}

.title img {
    width: 46px;
    height: 20px;
    margin-bottom: 4px;
}

.goods-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.goods-name span {
    color: #5542F6;
}


.prog-ls-top {
    position: relative;
    padding: 4px;
    display: flex;
    align-items: center;
}

.prog-ls-top a {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    background: #E8ECF9;
    height: 20px;
    margin-right: 5px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
}

.prog-ls-top img {
    margin-right: 12px;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    border: 1px solid #EBEAED;
}

::v-deep .ant-modal-body {
    padding: 0;
}
</style>
