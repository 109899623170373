<template>
    <a-modal v-model="selectVisible" @ok="addProjectConfirm" :width="620" @cancel="addProjectCancel">
        <div class="modal-header">
            <span class="modal-title">
                {{ selectFormType == 0 ? '请选择' : '查看' }}
            </span>
            <img v-if="modifiedProjectData.type == 1" style="height:20px;margin-bottom:6px;"
                src="https://qiniu.youjiamall.cn/crm-danpin.png" />
            <img v-if="modifiedProjectData.type == 2" style="height:20px;margin-bottom:6px;"
                src="https://qiniu.youjiamall.cn/crm-zuhepin.png" />
        </div>
        <!-- 单品 -->
        <div v-if="modifiedProjectData.type == 1">
            <div class="spu-main">
                <p v-if="modifiedProjectData.newGoodsSpecVoList && modifiedProjectData.newGoodsSpecVoList[0].skuList.length > 1"
                    style="color:red;margin:16px 0;">
                    <a-icon type="exclamation-circle" style="margin-right:5px;" />如当前产品有多个规格，用户可选择下单
                </p>
                <div v-for="(item, index) in modifiedProjectData.newGoodsSpecVoList" :key="index">
                    <div class="spu-main-top">
                        <p>
                            <img :src="modifiedProjectData.imagePath" />
                            <!-- <span>x{{ currentProjectData.count }}</span> -->
                        </p>
                        <div style="padding:7px 0;flex:1;">
                            <span style="color:#5542F6;text-decoration: underline">【{{ item.skuList[0].supplierId
                                }}】</span>
                            <span>{{ modifiedProjectData.name }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="prog-ls-top items-ls-top" style="margin:10px 0;">
                            <p style="display: flex;align-items:center;">
                                <a-popover>
                                    <template slot="content">
                                        7天无理由退换货
                                    </template>
                                    <a style="cursor: default;" href="javascript:;"
                                        v-if="item.skuList[0].returnable == 1">7</a>
                                </a-popover>

                                <a-popover>
                                    <template slot="content">
                                        有质检报告
                                    </template>
                                    <a style="cursor: default;" href="javascript:;"
                                        v-if="item.skuList[0].isQirPic == 1">质</a>
                                </a-popover>
                            </p>
                        </div>

                        <div style="display: flex;justify-content: center;margin-bottom:14px;"
                            v-if="modifiedProjectData.newGoodsSpecVoList[0].skuList.length > 1">
                            <a-radio-group v-model="oneType" @change="changeType" :disabled="isSelectFormBatchId != ''">
                                <a-radio-button :value="1">
                                    单规格加入组合
                                </a-radio-button>
                                <a-radio-button :value="2">
                                    单品多规格加入组合 (用户仅可选择任一规格下单)
                                </a-radio-button>
                            </a-radio-group>
                        </div>
                        <div class="spec-one-main">
                            <div style="display:flex">
                                <p style="padding-top:6px; margin-bottom: 10px;"><span
                                        style="color:red;">*</span>规格(可多选)</p>
                                <div class="addNum" style="margin-left:auto;" v-if="oneType == 2">
                                    <p style="border-right:1px solid rgba(0,0,0,.15);" @click="comCount('remove')">-</p>
                                    <span>{{ modifiedProjectData.count }}</span>
                                    <p style="border-left:1px solid rgba(0,0,0,.15);" @click="comCount('add')">+</p>
                                </div>
                            </div>
                            <div v-for="(sku, skuIndex) in item.skuList" :key="skuIndex"
                                style="margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #eee;padding-bottom: 10px;">
                                <div class="spec-box-one" @click="skuChange(index, skuIndex)"
                                    :class="{ 'skuActive': sku.checked }">
                                    <img :src="sku.goodsSpecImagePath" alt="">
                                    {{ sku.specsAttributeValue }}
                                </div>
                                <div class="spec-box-tips">
                                    <div class="remind">
                                        <span v-if="sku.goodsSpecPriceChange == 1">价格变化</span>
                                        <span v-if="sku.goodsSpecStatus == 2" style="margin-left:5px;">商品下架</span>
                                    </div>
                                </div>
                                <div class="spec-box-one-line">
                                    <div style="display:flex;margin-bottom:8px;">
                                        <span
                                            style="font-family: PingFangSC, PingFang SC;font-weight: 500;font-size: 16px;color: #FF0000;">¥{{
        sku.price }}</span>
                                        <div class="prog-ls-top items-ls-top">
                                            <p style="display: flex;align-items:center;margin-left: 5px;">
                                                <a-popover>
                                                    <template slot="content">
                                                        <p>{{ sku.threeTypeName ? sku.threeTypeName : sku.typeName }}:{{
        sku.marketPrice ? sku.marketPrice.toFixed(2) : '' }}</p>
                                                        <p><span>市场价:</span>¥{{ sku.plfPrice ? sku.plfPrice.toFixed(2) :
        '' }}</p>
                                                    </template>
                                                    <a style="cursor: default;" href="javascript:;"
                                                        v-if="sku.marketPrice">¥</a>
                                                </a-popover>
                                                <a @click="openH(sku.thirdUrl)" target="_blank"
                                                    v-if="sku.thirdUrl">H</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="addNum" style="margin-left:auto;border-bottom: 1px solid #EBEAED;"
                                        v-if="sku.checked && oneType == 1">
                                        <p style="border-right:1px solid rgba(0,0,0,.15);"
                                            @click="skuCount(index, skuIndex, 'remove')">-</p>
                                        <span>{{ sku.count }}</span>
                                        <p style="border-left:1px solid rgba(0,0,0,.15);"
                                            @click="skuCount(index, skuIndex, 'add')">+</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="selectFormType == 0">
                <div v-if="($route.query.subType == 102 || $route.query.subType == 103)" style="padding:10px 20px;">
                    <div style="height:10px;background: #F4F4F4;"></div>
                    <p style="font-size:14px;color:#010101;margin:10px 0;"><span style="color:red">*</span>请选择要加入的组合
                    </p>
                    <a-radio-group v-model="checkedGroup" @change="popSelectChange">
                        <div v-for="(item, index) in groupList" :key="index" style="margin-bottom: 10px;">
                            <a-radio :value="item.id">
                                {{ item.name }}
                            </a-radio>
                        </div>
                    </a-radio-group>
                </div>
            </div>
        </div>
        <!-- 组合品 -->
        <div v-if="modifiedProjectData.type == 2">
            <div class="spu-tit" style="padding-top: 0;">
                <p>{{ modifiedProjectData.name }}</p>
                <div style="display:flex;align-items:center;justify-content: space-between">
                    <span v-if="modifiedProjectData.price">¥{{ modifiedProjectData.price.toFixed(2) }}</span>
                    <div class="addNum">
                        <p style="border-right:1px solid rgba(0,0,0,.15);" @click="checkComCount(0)">-</p>
                        <span>{{ modifiedProjectData.count }}</span>
                        <p style="border-left:1px solid rgba(0,0,0,.15);" @click="checkComCount(1)">+</p>
                    </div>
                </div>
            </div>
            <div class="spu-main">
                <p style="color:red;margin:16px 0;"><a-icon type="exclamation-circle"
                        style="margin-right:5px;" />组合商品已经帮您搭配好，无法更改产品数量及规格</p>
                <div v-for="(item, index) in modifiedProjectData.newGoodsSpecVoList" :key="index">
                    <div class="spu-main-top">
                        <p>
                            <img
                                :src="item.skuList[item.skuIdx].goodsSpecImagePath ? item.skuList[item.skuIdx].goodsSpecImagePath : item.skuList[item.skuIdx].specGoodsImagePath" />
                            <span>x{{ item.skuList[item.skuIdx].goodsSpecCount }}</span>
                        </p>
                        <div style="padding:7px 0;flex:1;">
                            <span style="color:#5542F6;text-decoration: underline">【{{ item.skuList[item.skuIdx].supplierId }}】</span>
                            <span>{{ item.goodsName }}</span>
                            <div class="remind" style="margin-left:10px;margin-bottom:10px;" v-if="item.goodsStatus == 2">
                                <span>商品下架</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="prog-ls-top items-ls-top" style="margin:10px 0;">
                            <p style="display: flex;align-items:center;">
                                <a-popover>
                                    <template slot="content">
                                        7天无理由退换货
                                    </template>
                                    <a style="cursor: default;" href="javascript:;"
                                        v-if="item.skuList[item.skuIdx].returnable == 1">7</a>
                                </a-popover>

                                <a-popover>
                                    <template slot="content">
                                        有质检报告
                                    </template>
                                    <a style="cursor: default;" href="javascript:;"
                                        v-if="item.skuList[item.skuIdx].isQirPic == 1">质</a>
                                </a-popover>
                            </p>
                        </div>
                        <div class="spu-main-spec">
                            <p style="padding-top:6px;">规格</p>
                            <div>
                                <div @click.stop="specCheckCom(index, skuIndex)" class="spec-box" 
                                    v-for="(sku, skuIndex) in item.skuList" :key="skuIndex"
                                    :class="{ 'spec-box-active': skuIndex == item.skuIdx }"
                                    style="margin-bottom: 10px;cursor: pointer;display:flex;align-items:center;">
                                    <span>{{ sku.specsAttributeValue }}</span>
                                    <div class="remind" style="margin-left:10px;" v-if="sku.goodsSpecStatus == 2">
                                        <span >商品下架</span>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="selectFormType == 0">
                <div v-if="$route.query.subType == 102 || $route.query.subType == 103" style="padding:10px 20px;">
                    <div style="height:10px;background: #F4F4F4;"></div>
                    <p style="font-size:14px;color:#010101;margin:10px 0;"><span style="color:red">*</span>请选择要加入的组合
                    </p>
                    <a-radio-group v-model="checkedGroup" @change="popSelectChange">
                        <div v-for="(item, index) in groupList" :key="index" style="margin-bottom: 10px;">
                            <a-radio :value="item.id">
                                {{ item.name }}
                            </a-radio>
                        </div>
                    </a-radio-group>
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { request } from '@/utils/request'
export default {
    name: 'selectForm',
    props: {
        selectVisible: {
            type: Boolean,
            default: false
        },
        currentProjectData: {
            type: Object,
            default: () => { }
        },
        groupList: {
            type: Array,
            default: () => { }
        },
        // 单品id
        isSelectFormBatchId: {
            type: String,
            default: ""
        },
        //组合品数据
        isSelectFormData: {
            type: Object,
            default: () => { }
        },
        selectFormType: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            checkedGroup: "",
            currentSkuIndex: -1,
            checkList: [],//选中的规格
            oneType: 1,
            modifiedProjectData: {},
            currentIndex: 0,
        }
    },
    watch: {
        selectVisible() {
            this.checkedGroup = ""
            this.modifiedProjectData = JSON.parse(JSON.stringify(this.currentProjectData));
            if (this.modifiedProjectData.newGoodsSpecVoList.length > 0) {
                if (this.modifiedProjectData.newGoodsSpecVoList[0].skuList.length === 1) {
                    this.$set(this.modifiedProjectData.newGoodsSpecVoList[0].skuList, 0, {
                        ...this.modifiedProjectData.newGoodsSpecVoList[0].skuList[0],
                        checked: true
                    });
                }
            }
            this.$set(this.modifiedProjectData, 'count', 1)
            if (this.modifiedProjectData.newGoodsSpecVoList) {
                this.modifiedProjectData.newGoodsSpecVoList.forEach(item => {
                    item.skuIdx = 0;
                })
            }

        },
        selectFormType(val) {
            if (val == 1) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/updateDetail/' + this.isSelectFormBatchId, 'post', {}).then(res => {
                    this.modifiedProjectData = res.data.data;
                    this.modifiedProjectData.name = res.data.data.goodsName;
                    this.modifiedProjectData.type = 1;
                    // 用来存储结果的数组
                    const result = [];
                    // 遍历原始数据
                    this.modifiedProjectData.goodsSpecVoList.forEach(it => {
                        this.modifiedProjectData.imagePath = it.specGoodsImagePath
                        it.count = 1;
                        // 查找 result 中是否已经有该 goodsId 的对象
                        const existing = result.find(group => group.goodsId === it.specGoodsId);
                        if (existing) {
                            // 如果找到，直接将当前 item 加入 skuList
                            existing.skuList.push(it);
                        } else {
                            // 如果没有找到，创建一个新的对象
                            result.push({
                                goodsId: it.specGoodsId,
                                goodsName: it.specGoodsName,
                                imagePath: it.specGoodsImagePath,
                                goodsSpecCount: it.goodsSpecCount,
                                skuList: [it]
                            });
                        }
                    });
                    this.modifiedProjectData.newGoodsSpecVoList = result;
                    this.oneType = this.modifiedProjectData.goodsSpecType

                })
            }
            console.log(val, 'val')
            if (val == 2) {
                console.log(this.isSelectFormData, 'isSelectFormData')
                this.modifiedProjectData = this.isSelectFormData
                this.modifiedProjectData.count = 1;
                this.modifiedProjectData.name = this.modifiedProjectData.goodsName
                this.modifiedProjectData.newGoodsSpecVoList = [...this.isSelectFormData.spuList]
                this.modifiedProjectData.price = this.modifiedProjectData.newGoodsSpecVoList[0].packagePrice || 0
                if (this.modifiedProjectData.newGoodsSpecVoList) {
                    this.modifiedProjectData.newGoodsSpecVoList.forEach(item => {
                        item.skuIdx = 0;
                    })
                }

            }
        }
    },
    methods: {
        specCheckCom(index, skuIndex) {
            console.log(index, skuIndex)
            // this.currentIndex = skuIndex;
            this.$set(this.modifiedProjectData.newGoodsSpecVoList[index], 'skuIdx', skuIndex);
            this.$forceUpdate()
        },
        openH(url) {
            window.open(url)
        },
        // 增减单品多规格数量
        comCount(type) {
            // 详情
            if (this.selectFormType != 0) {
                return;
            }
            if (type == 'add') {
                this.modifiedProjectData.count++;
            } else {
                if (this.modifiedProjectData.count > 1) {
                    this.modifiedProjectData.count--
                }
            }
            this.$forceUpdate();
        },
        // 切换单品多规格
        changeType() {
            if (this.selectFormType != 0) {
                return;
            }
            if (this.modifiedProjectData.newGoodsSpecVoList.length > 0) {
                this.modifiedProjectData.newGoodsSpecVoList.forEach(item => {
                    if (item.skuList.length > 0) {
                        item.skuList.forEach(sku => {
                            if (sku.checked) {
                                sku.checked = false;
                                sku.count = 1;
                            }
                        })
                    }

                })
            }

        },
        //组合品数量
        checkComCount(type) {
            if (this.selectFormType != 0) {
                return;
            }
            if (type == 1) {
                this.modifiedProjectData.count++;
            } else {
                if (this.modifiedProjectData.count > 1) {
                    this.modifiedProjectData.count--
                }
            }
            this.$forceUpdate();
        },
        popSelectChange(e) {
            this.checkedGroup = e.target.value;
        },

        // 关闭选择商品弹窗
        addProjectCancel() {
            this.checkedGroup = ""
            this.$emit('addProjectCancel')
        },
        addProjectConfirm() {
            if (this.selectFormType != 0) {
                this.$emit('addProjectCancel')
                return;
            }
            // 单品
            if (this.modifiedProjectData.type == 1) {
                this.checkList = []
                let checkListInfo = []
                if (this.modifiedProjectData.newGoodsSpecVoList) {
                    this.modifiedProjectData.newGoodsSpecVoList.forEach(item => {
                        if (item.skuList.length > 0) {
                            item.skuList.forEach(sku => {
                                if (sku.checked) {
                                    this.checkList.push(sku.goodsSpecId)
                                    checkListInfo.push({ goodsSpecId: sku.goodsSpecId, count: sku.count })
                                }
                            })
                        }
                    })
                }

                if (this.checkList.length == 0) {
                    this.$message.warn('请选择规格')
                    return false;
                }
                if (!this.checkedGroup && this.$route.query.subType != 101) {
                    this.$message.warn('请选择组合')
                    return false;
                }

                let data = {
                    checkedGroup: this.checkedGroup,
                    checkListInfo: checkListInfo,
                    checkList: this.checkList,
                    type: this.oneType,
                }
                if (this.$route.query.subType == 101) {
                    data.checkedGroup = this.groupList[0].id
                }
                this.modifiedProjectData.count > 1 ? data.count = this.modifiedProjectData.count : data.count = 1
                this.$emit('addProjectConfirm', data)
            }
            // 组合品
            if (this.modifiedProjectData.type == 2) {
                let data = {
                    checkedGroup: this.checkedGroup,
                    checkList: this.checkList,
                    type: this.oneType,
                    count: this.modifiedProjectData.count
                }
                if (!this.checkedGroup && this.$route.query.subType != 101) {
                    this.$message.warn('请选择组合')
                    return false;
                }
                if (this.$route.query.subType == 101) {
                    data.checkedGroup = this.groupList[0].id
                }
                this.$emit('addProjectConfirmProd', data)
            }

        },
        // 单选选中sku
        skuChange(index, skuIndex) {
            if (this.selectFormType != 0) {
                return;
            }
            if (this.modifiedProjectData.newGoodsSpecVoList[index].skuList[skuIndex].checked) {
                this.modifiedProjectData.newGoodsSpecVoList[index].skuList[skuIndex].checked = false;
            } else {
                this.modifiedProjectData.newGoodsSpecVoList[index].skuList[skuIndex].checked = true;
            }

            this.$forceUpdate();
            // this.currentSkuIndex = skuIndex;
        },
        skuCount(index, skuIndex, type) {
            if (this.selectFormType != 0) {
                return;
            }
            if (type == 'add') {
                this.modifiedProjectData.newGoodsSpecVoList[index].skuList[skuIndex].count++
            } else {
                if (this.modifiedProjectData.newGoodsSpecVoList[index].skuList[skuIndex].count > 1) {
                    this.modifiedProjectData.newGoodsSpecVoList[index].skuList[skuIndex].count -= 1
                }
            }
            this.$forceUpdate();
        }
    }
}
</script>

<style scoped>
.spec-box {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #DCDCDC;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #000000;
    margin-right: 12px;
    padding: 8px;
}

/* // 弹窗header */
.modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
    margin-bottom: 10px;
}

.modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}

/* // spu弹窗 */
.spu-tit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 17px;
    color: #000000;
    padding: 20px 20px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #E7E7E7;
}

.spu-tit span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #FF0000;
}

/* // 加减 */
.addNum {
    display: flex;
    height: 32px;
    border-radius: 4px;
    width: 88px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.addNum input {
    border: none;
}

.addNum p {
    height: 32px;
    width: 24px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.addNum span {
    height: 32px;
    width: 40px;
    line-height: 32px;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
}

::v-deep .ant-modal-body {
    padding: 0;
}

.spu-main {
    padding: 0 20px;
    box-sizing: border-box;
}

.spu-main-top {
    display: flex;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.spu-main-top p {
    width: 80px;
    height: 80px;
    margin-right: 7px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
}

.spu-main-top p span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 18px;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    color: #fff;
}

.spu-main-top img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.spu-main-spec {
    display: flex;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}

.spu-main-spec>div {
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
    flex: 1;
}



/* 单品 */
.spec-one-main {}

p {
    margin: 0;
    padding: 0;
}

.spec-box-one {
    cursor: pointer;
    padding: 6px;
    box-sizing: border-box;
    height: 67px;
    border-radius: 4px;
    border: 1px solid #ABABAB;
}

.spec-box-one img {
    width: 55px;
    height: 55px;
    margin-right: 8px;
    object-fit: contain;
}

.spec-box-one-line {
    margin-left: auto;
}


.prog-ls-top {
    position: relative;
    /* padding: 4px; */
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #EBEAED; */
}

.prog-ls-top a {
    display: inline-block;
    width: 20px;
    background: #E8ECF9;
    height: 20px;
    margin-right: 5px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
}

.prog-ls-top img {
    margin-right: 12px;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    border: 1px solid #EBEAED;
}


.skuActive {
    border: 2px solid #5542F6;
    box-sizing: border-box;
}

.spec-box-active {
    border: 2px solid #5542F6;
    box-sizing: border-box;
}

.spec-box-tips {
    padding-left: 10px;
    box-sizing: border-box;
}



.remind span {
    border-radius: 3px 0px 8px 0px;
    display: inline-block;
    height: 20px;
    padding: 0 4px;
    line-height: 20px;
    font-size: 12px !important;
    background: red;
    border-radius: 4px;
    color: #fff;
    position: relative !important;
}
</style>