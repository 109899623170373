<template>
    <div>
        <div v-for="(spu, spuIndex) in spuList" :key="spuIndex">
            <div v-if="spu.skuList && spu.skuList.length > 0">
                <div>
                    <div class="items-zh-info">
                        <div v-if="spu.skuList">
                            <div v-for="(skuSpec, skuSpecIndex) in spu.skuList" :key="skuSpecIndex">
                                <div class="df left-goods" v-if="skuSpecIndex == 0">
                                    <div style="margin-right: 8px;cursor: pointer;" class="left-goods-p"
                                        @click="sendChangeDetail">
                                            <div class="remind" v-if="skuSpec.remind"><span>{{ skuSpec.remind }}</span>
                                        </div>
                                        <img v-if="skuSpec.type == 1"
                                            style="border: 1px solid #ABABAB;object-fit: contain;"
                                            :src="skuSpec.specGoodsImagePath" />
                                        <img v-if="skuSpec.type == 2"
                                            style="border: 1px solid #ABABAB;object-fit: contain;"
                                            :src="skuSpec.goodsSpecImagePath" />
                                        <span>x{{ skuSpec.goodsSpecCount }}</span>
                                    </div>
                                    <div style="flex: 1;">
                                        <p class="ls-top-tit text-wrap2" @click="sendChangeDetail"
                                            style="cursor: pointer;">
                                            <span v-if="skuSpec.supplierId"
                                                style="color:#5542F6;  text-decoration: underline;">
                                                【{{ skuSpec.supplierId }}】</span>
                                            <span class="left-goods-name">
                                                {{ spu.goodsName }}
                                            </span>
                                        </p>
                                        <p>
                                            <span v-if="skuSpec.type == 1" class="price-text">¥{{ skuSpec.price ?  skuSpec.price.toFixed(2) : skuSpec.platformDiscountPrice.toFixed(2) }}</span>
                                            <span v-if="skuSpec.price || skuSpec.platformDiscountPrice && skuSpec.type == 1" style="margin: 0 2px;">|</span>
                                            <span class="spec-name">{{ skuSpec.specsAttributeValue }}</span>
                                            <span style="color:#5542F6;width:50px"
                                                v-if="spu.skuList.length > 1">【多规格】</span>
                                        </p>
                                        <div style="position:relative;width: 100%;">
                                            <div class="prog-ls-top items-ls-top">
                                                <p style="display: flex;align-items:center;">
                                                    <a @click="openH(skuSpec.thirdUrl)" target="_blank"
                                                        v-if="skuSpec.thirdUrl">H</a>
                                                    <a-popover>
                                                        <template slot="content">
                                                            7天无理由退换货
                                                        </template>
                                                        <a style="cursor: default;" href="javascript:;"
                                                            v-if="skuSpec.returnable == 1 && skuSpec.type == 1">7</a>
                                                    </a-popover>

                                                    <a-popover>
                                                        <template slot="content">
                                                            有质检报告
                                                        </template>
                                                        <a style="cursor: default;" href="javascript:;"
                                                            v-if="skuSpec.isQirPic == 1">质</a>
                                                    </a-popover>
                                                    <a-popover>
                                                        <template slot="content">
                                                            <div style="display:flex">
                                                                <span>{{ skuSpec.threeTypeName }}:</span>
                                                                <p>¥{{ skuSpec.marketPrice ? skuSpec.marketPrice.toFixed(2) : '' }}
                                                                </p>
                                                            </div>
                                                            <p>市场价:¥{{ skuSpec.plfPrice ? skuSpec.plfPrice.toFixed(2) : '' }} </p>
                                                        </template>
                                                        <a style="cursor: default;" href="javascript:;">¥</a>
                                                    </a-popover>
                                                </p>
                                            </div>
                                            <!-- <div class="remind remindNew" v-if="skuSpec.remind"><span>下架提醒</span></div> -->
                                            <div class="remind remindNew" v-if="(spu.goodsSpecPriceChange == 1 || spu.goodsSpecStatus == 2) && goodsType == 1">
                                                <span v-if="spu.goodsSpecPriceChange == 1">价格变化</span>
                                                <span v-if="spu.goodsSpecStatus == 2" style="margin-left:5px;">商品下架</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        sendChangeDetail() {
            this.$emit('changeEdit', this.selIndex, this.selGoodsIndex)
        },
        openH(url) {
            window.open(url)
        }
    },
    props: {
        spuList: {
            type: Object,
            default: () => []
        },
        selIndex: {
            type: String,
            default: ''
        },
        selGoodsIndex: {
            type: String,
            default: ''
        },
        goodsType:{
            type: String,
            default: ''
        }
    },
}
</script>



<style scoped>
.spec-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #0D0A19;
}

.df {
    display: flex;
}

.left-goods {
    margin-bottom: 14px;
    margin-top: 10px;
}

.left-goods>p,
.left-goods img {
    width: 88px;
    height: 88px;
    background: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;

}



.prog-goods-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #0D0A19;
}

.price-text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #FF0000;
}




.left-goods-p {
    position: relative;
}

.left-goods-p span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 18px;
    background: rgba(0, 0, 0, .6);
    text-align: center;
    color: #fff;
    line-height: 18px;
    font-size: 12px;
}

.items-ls-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    border: none !important;
}


.prog-ls-top {
    position: relative;
    padding: 4px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEAED;
}

.prog-ls-top a {
    display: inline-block;
    width: 16px;
    background: #E8ECF9;
    height: 16px;
    margin-right: 5px;
    text-align: center;
    font-size: 12px;
}

.prog-ls-top img {
    margin-right: 12px;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    border: 1px solid #EBEAED;
}

p {
    margin: 0;
    padding: 0
}


.left-goods-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600 !important;
    font-size: 13px !important;
    color: #0D0A19 !important;
}


.remind {
    position: absolute;
    left: 0;
    top: -2px;
}

.remind span {
    border-radius: 3px 0px 8px 0px;
    display: inline-block;
    height: 20px;
    padding: 0 4px;
    line-height: 20px;
    font-size: 12px;
    background: red;
    position: relative !important;
}
.remindNew {
    right: -10px;
    top: 0;
    display: flex;
    justify-content: flex-end;
   
}
.remindNew span{
    border-radius: 4px;
    color: #fff;
}
</style>