<template>
    <div class="page">
        <a-card style="background-color: #fff; " class="main-box">

            <!-- // 101货堆 102 宅配103 提货 -->
            <div
                style="height: calc(100vh - 95px);display:flex;border: 1px solid #F3F3F3;border-bottom: 0;width: 100%;">
                <div class="left-top" style="position: relative;">
                    <a-tabs v-model="listType" @change="changeGroupType">
                        <a-tab-pane :key="0" tab="方案列表">
                            <div
                                style="display:flex;align-items: center;padding:0 10px 10px;color:#010101;font-size:13px;">
                                <a-input v-model="planName" :max-length="20" placeholder="请输入方案名称"
                                    style="padding: 0 10px;height:25px;" @blur="editName" />
                                <!-- <a @click="editName" style="width:60px;text-align:center;font-size:14px;">保存</a> -->
                            </div>
                            <div class="group-tips">
                                <p>
                                    <span>{{ $route.query.subType == 101 ? '合计产品预算' : '产品预算/份' }}:</span>
                                    <span style="color:red;">¥{{ this.$route.query.marketPrice }}</span>
                                </p>
                                <p v-if="$route.query.subType == 101">
                                    <span style="color:red;">¥{{ getCostPrice(0) ? getCostPrice(0).toFixed(2) : '0.00'
                                        }}</span>
                                    <span style="margin:0 5px;color:#000">|</span>
                                    <span style="color:#000;">利润率:</span>
                                    <span style="color:red;" v-if="getProfit(0) >= 10">{{ getProfit(0) }}%</span>
                                    <span style="color:green;" v-else>{{ getProfit(0) }}%</span>
                                </p>
                                <div style="color: #5542F6;cursor:pointer" @click="closeAllGroup()"
                                    v-if="groupList.length > 0 && ($route.query.subType == 102 || $route.query.subType == 103)">
                                    {{ isAllopen ? '全部展开' : '全部收起' }}
                                    <a-icon v-if="!isAllopen" type="up-circle"
                                        style="font-size:14px;cursor: pointer;" />
                                    <a-icon v-else type="down-circle" style="font-size:14px;cursor: pointer;" />
                                </div>
                            </div>
                            <div style="padding: 0 10px 10px;"
                                v-if="$route.query.subType == 102 || $route.query.subType == 103">
                                <a-button style="width:100%;border: 1px solid #5542F6;color: #5542F6;"
                                    @click="addCombination">创建组合</a-button>
                            </div>
                            <!--  组列表 -->
                            <div class="prog-mai" v-if="groupList.length > 0">
                                <a-checkbox-group :disabled="$route.query.subType == 101">
                                    <a-list class="demo-loadmore-list" item-layout="horizontal"
                                        :data-source="groupList">
                                        <a-list-item slot="renderItem" slot-scope="item,index">
                                            <div class="prog-mai-item">
                                                <!-- 组合名 -->
                                                <div class="prog-tit" slot="header"
                                                    v-if="$route.query.subType == 102 || $route.query.subType == 103">
                                                    <div style="width:100%;padding:15px 10px;box-sizing: border-box">
                                                        <div
                                                            style="display:flex;align-items: center;width:100%;padding-left: 8px;">
                                                            <a-checkbox :value="item.id" style="margin-right: 10px;"
                                                                @change="selectChange" />
                                                            <div v-if="$route.query.subType == 102 || $route.query.subType == 103"
                                                                style="width:100%;display:flex;align-items:center;">
                                                                <a-input v-model="item.name"
                                                                    @blur="editGroupName(index)" placeholder="组合名称"
                                                                    size="small"
                                                                    style="width:100%;margin-right: 5px;padding-left:8px;"></a-input>

                                                            </div>
                                                        </div>
                                                        <div style="padding-left:40px;margin-top:4px;display: flex;">
                                                            <p>
                                                                <span style="color:red;">¥{{ getCostPrice(index) ?
                        getCostPrice(index).toFixed(2) : '0.00' }}</span>
                                                                <span style="margin:0 5px;color:#000">|</span>
                                                                <span style="color:#000;">利润率:</span>
                                                                <span style="color:red;"
                                                                    v-if="getProfit(index) >= 10">{{
                        getProfit(index) }}%</span>
                                                                <span style="color:green;" v-else>{{ getProfit(index)
                                                                    }}%</span>
                                                                <!-- <span style="color:red;">{{ getProfit(index) }}%</span> -->
                                                            </p>

                                                            <div
                                                                style="margin-left: auto;font-size: 13px;display: flex;">
                                                                <div style="display:flex;" class="goods-action">
                                                                    <div
                                                                        @click="changeGroupAction(1, index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-ups.png" />
                                                                    </div>
                                                                    <div
                                                                        @click="changeGroupAction(2, index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-up.png" />
                                                                    </div>
                                                                    <div
                                                                        @click="changeGroupAction(3, index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-down.png" />
                                                                    </div>
                                                                    <div
                                                                        @click="changeGroupAction(4, index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-downs.png" />
                                                                    </div>
                                                                </div>
                                                                <div style="padding-top:4px;margin-left: 10px;">
                                                                    <a-icon v-if="!item.open" @click="checkOpen(index)"
                                                                        type="down-circle"
                                                                        style="font-size:14px;cursor: pointer;" />
                                                                    <a-icon v-if="item.open" @click="checkOpen(index)"
                                                                        type="up-circle"
                                                                        style="font-size:14px;cursor: pointer;" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="item.open">
                                                    <div v-if="item.goodsList && item.goodsList.length > 0">
                                                        <div v-for="(goods, goodsIndex) in item.goodsList"
                                                            :key="goodsIndex"
                                                            style="border-bottom:1px solid #F3F3F3;padding:8px 16px;">
                                                            <div class="prog-goods-action">
                                                                <p class="prog-goods-name">
                                                                    <img v-if="goods.type == 1" style="height:20px;"
                                                                        src="https://qiniu.youjiamall.cn/crm-danpin.png" />
                                                                    <img v-if="goods.type == 2" style="height:20px;"
                                                                        src="https://qiniu.youjiamall.cn/crm-zuhepin.png" />
                                                                    商品{{ (goodsIndex + 1).toString().padStart(2, '0') }}
                                                                </p>
                                                                <!-- 商品操作删除 -->
                                                                <div style="display:flex;" class="goods-action">
                                                                    <div @click="changeAction(1, index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-goods-ups.png" />
                                                                    </div>
                                                                    <div @click="changeAction(2, index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-goods-up.png" />
                                                                    </div>
                                                                    <div @click="changeAction(3, index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-goods-down.png" />
                                                                    </div>
                                                                    <div @click="changeAction(4, index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-goods-downs.png" />
                                                                    </div>
                                                                    <p @click="changeEdit(index, goodsIndex)">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-goods-details.png" />
                                                                    </p>
                                                                    <p @click="checkGroup(index, goodsIndex)"
                                                                        v-if="$route.query.subType != 101">
                                                                        <img
                                                                            src="https://qiniu.youjiamall.cn/pc/crm/crm-genghuan.png" />
                                                                    </p>
                                                                    <p @click="changeAction('del', index, goodsIndex)">
                                                                        <img src="https://qiniu.youjiamall.cn/pc/crm/crm-goods-del.png"
                                                                            style="margin-right:0;" />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="prog-goods-name" v-if="goods.type == 2"
                                                                style="cursor:pointer;"
                                                                @click="changeEdit(index, goodsIndex)">
                                                                <p style="margin-top:8px;display:flex;align-items: center;"
                                                                    class="left-goods-name">
                                                                    {{ goods.goodsName }}                                                                    
                                                                </p>
                                                                <div style="display:flex;align-items: center;">
                                                                    <span class="price-text">¥{{
                        goods.spuList[0].packagePrice ?
                            goods.spuList[0].packagePrice.toFixed(2) :
                            '0.00' }}</span>
                                                                    <div class="prog-ls-top items-ls-top">
                                                                        <p style="display: flex;align-items:center;">
                                                                            <a-popover>
                                                                                <template slot="content">
                                                                                    7天无理由退换货
                                                                                </template>
                                                                                <a style="cursor: default;"
                                                                                    href="javascript:;"
                                                                                    v-if="goods.spuList[0].skuList[0].returnable == 1">7</a>

                                                                            </a-popover>
                                                                        </p>
                                                                    </div>
                                                                    <div class="remind" >
                                                                        <span v-if="goods.spuList[0].skuList[0].packagePriceChange == 1">价格变化</span>
                                                                        <span v-if="goods.goodsStatus == 2 || goods.specGoodsStatus == 2 || goods.goodsSpecStatus == 2" style="margin-left:5px;">商品下架</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <goodsInfoTips @changeEdit="changeEdit" :goodsType="goods.type"
                                                                    :selIndex="index" :selGoodsIndex="goodsIndex"
                                                                    :spuList="goods.spuList">
                                                                </goodsInfoTips>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else style="padding:10px;box-sizing:border-box;">
                                                        <a-empty :image-style="{ height: '40px', }"
                                                            style="font-size:12px" />
                                                    </div>
                                                </div>
                                            </div>
                                        </a-list-item>
                                    </a-list>
                                </a-checkbox-group>
                            </div>
                            <div v-else style="padding-top: 200px;">
                                <a-empty />
                            </div>
                            <div class="prog-btm">
                                <p v-if="$route.query.subType == 101"
                                    style="color:red;font-weight:400;margin-bottom:10px;">
                                    提示:合计优加底价不能高于合计产品预算</p>
                                <p v-if="$route.query.subType == 102 || $route.query.subType == 103"
                                    style="color:red;font-weight:400;margin-bottom:10px;">
                                    提示:每组利润率低于10%无法导出方案</p>
                                <div ref="progBtm"
                                    style=" display: flex;align-items: center;justify-content: space-around;">
                                    <a-button v-if="$route.query.subType == 102 || $route.query.subType == 103"
                                        style="margin-left: 8px;border: 1px solid #fff;color: #fff;background: #5542F6;"
                                        @click="delConfirm">删除选中</a-button>
                                    <!-- <a-button v-if="$route.query.planStatus != 10"
                                        style="background-color: #fff;color: #5542F6" @click="groupSave">生成方案</a-button> -->
                                </div>
                            </div>
                            <div style="height: 62px;"></div>
                        </a-tab-pane>
                        <a-tab-pane :key="1" tab="回收站">
                            <p style="color:red;margin:4px 10px;" v-if="recycleList.length > 0"><a-icon
                                    type="exclamation-circle" style="margin-right:5px;" />30天自动清空回收站</p>
                            <!--  组列表 -->
                            <a-list class="demo-loadmore-list" item-layout="horizontal" :data-source="recycleList">
                                <a-list-item slot="renderItem" slot-scope="item,index">
                                    <div class="prog-mai-item">
                                        <!-- 组合名 -->
                                        <div v-if="item.goodsList && item.goodsList.length > 0">
                                            <div v-for="(goods, goodsIndex) in item.goodsList" :key="goodsIndex"
                                                style="border-bottom:1px solid #F3F3F3;padding:8px 16px;">
                                                <div class="prog-goods-action">
                                                    <p class="prog-goods-name">
                                                        <img v-if="goods.type == 1" style="height:20px;"
                                                            src="https://qiniu.youjiamall.cn/crm-danpin.png" />
                                                        <img v-if="goods.type == 2" style="height:20px;"
                                                            src="https://qiniu.youjiamall.cn/crm-zuhepin.png" />
                                                        商品{{ (goodsIndex + 1).toString().padStart(2, '0') }}
                                                    </p>
                                                    <!-- 回收站还原 -->
                                                    <div style="display:flex;">
                                                        <p @click="reloadGoods(index, goodsIndex)">
                                                            <img src="https://qiniu.youjiamall.cn/pc/crm/crm-restore.png"
                                                                style="width:20px;height:20px;cursor:pointer" />
                                                        </p>
                                                    </div>

                                                </div>
                                                <div class="prog-goods-name" v-if="goods.type == 2">
                                                    <p style="margin-top:8px;" class="left-goods-name">{{  goods.goodsName }}</p>
                                                    <span class="price-text">¥{{ goods.spuList[0].packagePrice ? goods.spuList[0].packagePrice.toFixed(2) : '0.00' }}</span>
                                                </div>
                                                <div>
                                                    <goodsInfoTips :spuList="goods.spuList"></goodsInfoTips>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </a-list-item>
                            </a-list>
                            <div v-if="recycleList[0].editNeedOrderPlanGroupDetailParamList.length == 0"
                                style="padding-top: 200px;">
                                <a-empty />
                            </div>
                        </a-tab-pane>
                    </a-tabs>

                </div>
                <!-- 产品池 -->
                <div class="items-right">
                    <div :class="advanced ? 'search' : null">
                        <a-form layout="horizontal">
                            <div :class="advanced ? null : 'fold'"
                                style="display:flex;flex-wrap:wrap;align-items: center;padding: 10px 0;">
                                <div style="display: flex;align-items: center;width:240px;margin: 0 10px 10px">
                                    <span style="color:rgba(0, 0, 0, 0.85);margin-right:5px;width:100px;">商品名称:</span>
                                    <a-input v-model="queryData.name" placeholder="请输入商品名称" />
                                </div>

                                <div style="display: flex;align-items: center;width:200px;margin: 0 10px 10px">
                                    <span style="color:rgba(0, 0, 0, 0.85);margin-right:5px">7天无理由:</span>
                                    <a-select v-model="queryData.returnable" style="width: 120px" placeholder="请选择"
                                        allowClear option-label-prop="label">
                                        <a-select-option value="0" label="未知">
                                            未知
                                        </a-select-option>
                                        <a-select-option value="1" label="支持">
                                            支持
                                        </a-select-option>
                                        <a-select-option value="2" label="不支持">
                                            不支持
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <div style="display: flex;align-items: center;width:200px;margin: 0 10px 10px">
                                    <span style="color:rgba(0, 0, 0, 0.85);margin-right:5px">质检报告:</span>
                                    <a-select v-model="queryData.isQirPic" style="width: 120px" placeholder="请选择"
                                        allowClear option-label-prop="label">
                                        <a-select-option value="1" label="是">
                                            是
                                        </a-select-option>
                                        <a-select-option value="0" label="否">
                                            否
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <div style="display: flex;align-items: center;width:240px;margin: 0 10px 10px">
                                    <span style="color:rgba(0, 0, 0, 0.85);margin-right:5px;width:100px;">供应商ID:</span>
                                    <a-input v-model="queryData.supplierId" placeholder="请输入供应商ID" />
                                </div>


                                <div style="display: flex;align-items: center;width:200px;margin: 0 10px 10px">
                                    <span style="color:rgba(0, 0, 0, 0.85);margin-right:5px">优加底价:</span>
                                    <a-input style="flex:1" v-model="queryData.minPrice" placeholder="最小" />
                                    <span style="margin:0 5px;">-</span>
                                    <a-input style="flex:1" v-model="queryData.maxPrice" placeholder="最大" />
                                </div>
                                <div
                                    style="display: flex;align-items: center;width:200px;margin-right:10px;margin-bottom:10px;">
                                    <span style="color:rgba(0, 0, 0, 0.85);margin-right:5px">电商价:</span>
                                    <a-input style="flex:1" v-model="queryData.minMarketPrice" placeholder="最小" />
                                    <span style="margin:0 5px;">-</span>
                                    <a-input style="flex:1" v-model="queryData.maxMarketPrice" placeholder="最大" />
                                </div>
                                <div
                                    style="display: flex;align-items: center;width:250px;margin: 0 10px;margin-bottom:10px;">
                                    <span style="color:rgba(0, 0, 0, 0.85);margin-right:5px">市场价:</span>
                                    <a-input style="flex:1" v-model="queryData.minAdvicePrice" placeholder="最小" />
                                    <span style="margin:0 5px;">-</span>
                                    <a-input style="flex:1" v-model="queryData.maxAdvicePrice" placeholder="最大" />
                                </div>

                                <a-button style="margin-right: 8px;width: 80px;margin-left:10px;margin-bottom:8px;"
                                    @click="resetChange">重置</a-button>
                                <a-button type="primary" style="width: 80px;margin-bottom:8px;"
                                    @click="searchChange">查询</a-button>
                                <a-divider />
                                <div class="select-list">
                                    <ul>
                                        <li v-for="(item, index) in goodsType"
                                            :class="typeCurrent == index ? 'active-sel' : ''" :key="item.id"
                                            @click="filterTypeChange(index)">
                                            <img v-if="typeCurrent == index" :src="item.selIcon" alt="">
                                            <img v-else :src="item.icon" alt="">
                                            {{ item.name }}
                                        </li>
                                    </ul>
                                    <span style="color:red;">提示:部分商品没有电商价格或相关链接</span>
                                </div>
                            </div>
                        </a-form>
                    </div>

                    <div>
                        <ul v-if="prodList.length > 0">
                            <li v-for="(item, index) in prodList" :key="index" style="position: relative;">
                                <div v-if="item.type == 1">
                                    <div class="items-tag">单品</div>
                                    <div style="text-align: center;" class="rig-img-box">
                                        <div style="width:70px;height:70px;"><img
                                                style="width:100%;height:100%;object-fit:contain" :src="item.imagePath"
                                                alt=""></div>
                                        <div v-if="item.goodsSpecVoList[item.idx]" style="flex:1;margin-left:5px;">
                                            <p class="ls-top-tit text-wrap3 ">
                                                <span style="color:#5542F6;  text-decoration: underline;">【{{
                        item.goodsSpecVoList[item.idx].supplierId }}】</span>
                                                {{ item.name }}
                                            </p>
                                            <div class="prog-ls-top items-ls-top">
                                                <p style="display: flex;align-items:center;">
                                                    <a @click="openH(item.goodsSpecVoList[item.idx].thirdUrl)"
                                                        target="_blank"
                                                        v-if="item.goodsSpecVoList[item.idx].thirdUrl">H</a>
                                                    <a-popover>
                                                        <template slot="content">
                                                            7天无理由退换货
                                                        </template>
                                                        <a style="cursor: default;" href="javascript:;"
                                                            v-if="item.goodsSpecVoList[item.idx].returnable == 1">7</a>
                                                    </a-popover>

                                                    <a-popover>
                                                        <template slot="content">
                                                            有质检报告
                                                        </template>
                                                        <a style="cursor: default;" href="javascript:;"
                                                            v-if="item.goodsSpecVoList[item.idx].isQirPic == 1">质</a>
                                                    </a-popover>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display:flex;align-items:center;margin-top:10px;">
                                        <p v-if="item.picList.length > 4" @click="prevImg(index)"
                                            style="cursor:pointer"><a-icon type="left" style="color:#A8A4A3" /></p>
                                        <div style="display:flex;width:156px;overflow: hidden;">
                                            <div v-for="(imgs, imgsIndex) in item.picList" :key="imgsIndex"
                                                style="cursor: pointer;">
                                                <img :class="{ 'imgActive': imgsIndex == item.idx }"
                                                    @click="checkSpecImsg(index, imgsIndex)"
                                                    style="width:35px;height:35px;border-radius: 4px;border:1px solid #ABABAB;margin-right: 4px;box-sizing:border-box;object-fit: contain;"
                                                    :src="imgs" alt="">
                                            </div>

                                        </div>
                                        <p v-if="item.picList.length > 4" @click="nextImg(index)"
                                            style="cursor:pointer"><a-icon type="right" style="color:#A8A4A3;" /></p>
                                    </div>
                                    <div class="items-info" v-if="item.goodsSpecVoList[item.idx]">
                                        <div class="items-title text-wrap2 ">
                                            {{ item.goodsSpecVoList[item.idx].specsAttributeValue }}
                                        </div>
                                        <div class="items-info-btm">
                                            <div>
                                                <span style="width:60px;">优加底价:</span>
                                                <p style="color: #FF0000;">¥{{ item.goodsSpecVoList[item.idx].price ?
                        item.goodsSpecVoList[item.idx].price.toFixed(2) : '' }}</p>
                                            </div>
                                            <div>
                                                <span style="width:60px;">{{
                        item.goodsSpecVoList[item.idx].threeTypeName }}:</span>
                                                <p>¥{{ item.goodsSpecVoList[item.idx].marketPrice ?
                        item.goodsSpecVoList[item.idx].marketPrice.toFixed(2) : '' }}</p>
                                            </div>
                                            <div>
                                                <span style="width:60px;">市场价:</span>
                                                <p>¥{{ item.goodsSpecVoList[item.idx].plfPrice ?
                        item.goodsSpecVoList[item.idx].plfPrice.toFixed(2) : '' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="item.type == 2" class="items-zh">
                                    <div class="items-tag" style="background:#FF0000;">组合品</div>
                                    <div style="height:20px;"></div>
                                    <div
                                        style="border-bottom:1px solid #E8E8E8;padding-bottom: 6px;margin-bottom:10px;">
                                        <p class="ls-top-tit text-wrap3">
                                            {{ item.name }}
                                        </p>
                                        <div class="items-info-btm" style="padding:0;margin-top:5px;">
                                            <div>
                                                <span>优加底价:</span>
                                                <p style="color: #FF0000;">¥{{ item.price ? item.price.toFixed(2) :
                        '0.00' }}</p>
                                            </div>
                                            <div style="display:flex;margin-top: 5px;">
                                                <div style="display:flex;">
                                                    <span>市场价:</span>
                                                    <p>¥{{ item.plfPrice ? item.plfPrice.toFixed(2) : '0.00' }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="items-zh-wrap">
                                        <div class="items-zh-info" v-for="(zh, zhIndex) in item.newGoodsSpecVoList"
                                            :key="zhIndex">
                                            <div v-for="(zhItem, zhItemIndex) in zh.skuList" :key="zhItemIndex">
                                                <div style="margin-bottom:5px;" v-if="zhItemIndex == 0">
                                                    <div class="items-zh-info-top">
                                                        <div class="goodsimg">
                                                            <img style="width:35px;height:35px"
                                                                :src="zh.goodsImagePath" />
                                                            <span>x{{ zhItem.goodsSpecCount }}</span>
                                                        </div>
                                                        <div>
                                                            <p class="ls-top-tit text-wrap2 " style="width:130px;">
                                                                <span
                                                                    style="color:#5542F6;  text-decoration: underline;">
                                                                    【{{ zhItem.supplierId }}】</span>
                                                                <span v-if="zh.goodsName">{{ zh.goodsName }}</span>
                                                                <span v-else>{{ zhItem.specsAttributeValue }}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="prog-ls-top items-ls-top">
                                                        <p style="display: flex;align-items:center;">
                                                            <a @click="openH(zhItem.thirdUrl)" target="_blank"
                                                                v-if="zhItem.thirdUrl">H</a>
                                                            <a-popover>
                                                                <template slot="content">
                                                                    7天无理由退换货
                                                                </template>
                                                                <a style="cursor: default;" href="javascript:;"
                                                                    v-if="zhItem.returnable == 1">7</a>
                                                            </a-popover>

                                                            <a-popover>
                                                                <template slot="content">
                                                                    有质检报告
                                                                </template>
                                                                <a style="cursor: default;" href="javascript:;"
                                                                    v-if="zhItem.isQirPic == 1">质</a>
                                                            </a-popover>
                                                        </p>
                                                    </div>
                                                    <div class="items-zh-info-tag ">
                                                        <p class="text-wrap1" v-if="zh.skuList.length == 1">
                                                            {{ zhItem.specsAttributeValue }}
                                                        </p>
                                                        <p class="text-wrap1" style="max-width:120px" v-else>
                                                            {{ zhItem.specsAttributeValue }}
                                                        </p>
                                                        <span v-if="zh.skuList.length > 1">[多规格]</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="height:110px;"></div>
                                <div class="items-btn">
                                    <div style="width: 26px;height: 26px;position:relative;cursor: pointer;line-height: 36px;"
                                        @click="openShopCart(item)">
                                        <a-icon style="font-size:22px;color:#5542F6" type="shopping-cart" />
                                        <span class="shop-num" v-if="item.goodsGroupCount">{{ item.goodsGroupCount
                                            }}</span>
                                    </div>
                                    <div>
                                        <a-button @click="goodsDetail(item)">查看详情</a-button>
                                        <a-button @click="createProg(item, 1)"
                                            v-if="$route.query.subType == 102 || $route.query.subType == 103">加入组合</a-button>
                                        <a-button @click="createProg(item, 0)" v-else>加入方案</a-button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div v-else class="items-empty">
                            <a-empty />
                        </div>
                        <div class="page-box">
                            <a-pagination :total="total" :current="currentPage" :pageSize="queryData.size"
                                @change="handlePageChange" />
                        </div>
                    </div>
                </div>

                <!-- 回收站 -->

                <!-- 选择加入哪个方案 / 规格 -->
                <selectForm :selectVisible="selectVisible" :groupList="groupList" @addProjectCancel="addProjectCancel"
                    :isSelectFormBatchId="isSelectFormBatchId" :currentProjectData="currentProjectData"
                    :isSelectFormData="isSelectFormData" :selectFormType="selectFormType"
                    @addProjectConfirm="addProjectConfirm" @addProjectConfirmProd="addProjectConfirmProd"></selectForm>

                <!-- 商品详情 -->
                <goodsDetailModal :goodsVisible="goodsVisible" :goodsDetailId="goodsDetailId"
                    :currentGoodsDetail="currentGoodsDetail" @goodsDetailCancel="goodsDetailCancel">
                </goodsDetailModal>

                <!-- 购物车弹窗 -->
                <a-modal :visible="cartVisible" @cancel="cartVisible = false" @ok="cartVisible = false" :width="400">
                    <div class="modal-header cart-box">
                        <p class="modal-title">
                            购物车 <span v-if="currentCartData.goodsGroupCount">({{ currentCartData.goodsGroupCount
                                }})</span>
                        </p>
                    </div>
                    <div style="padding:10px 30px 0px;"
                        v-if="currentCartData.goodsGroupVoList && currentCartData.goodsGroupVoList.length > 0">
                        <div class="cart-list"
                            :class="{ 'noBorder': index == currentCartData.goodsGroupVoList.length - 1 }"
                            v-for="(item, index) in currentCartData.goodsGroupVoList" :key="item.id">
                            <p>{{ $route.query.subType == 101 ? planName : item.name }}:</p>
                            <span>x{{ item.count }}</span>
                        </div>
                    </div>
                    <div style="padding:10px 30px;" v-else>
                        <a-empty />
                    </div>
                </a-modal>

                <!-- 组合 -->
                <a-modal :visible="openCheckGroup" @cancel="handleCheckGroupCancel" @ok="handleCheckGroup" :width="400">
                    <div style="padding:10px 20px;">
                        <p style="font-size:14px;color:#010101;margin:10px 0;"><span style="color:red">*</span>{{
                            listType == 0 ?
                            '请选择要加入的组合' : '请选择要恢复到哪个组合'}}
                        </p>
                        <a-radio-group v-model="checkedGroup">
                            <div v-for="(item, index) in groupList" :key="index" style="margin-bottom: 10px;">
                                <a-radio :value="item.id">
                                    {{ item.name }}
                                </a-radio>
                            </div>
                        </a-radio-group>
                    </div>
                </a-modal>

            </div>
        </a-card>
    </div>
</template>

<script>
import { request } from '@/utils/request'
import { EventBus } from '@/utils/event-bus.js'
// 选择商品和组合
import selectForm from './components/selectForm'
// 查看详情
import goodsDetailModal from './components/goodsDetail'

//H 7 质
import goodsInfoTips from './components/goodsInfoTips'

export default {
    name: 'QueryList',
    components: { goodsDetailModal, selectForm, goodsInfoTips },
    data() {
        return {

            iptmd: 4,
            iptsm: 12,
            form: this.$form.createForm(this),
            queryData: {
                type:1,
                "page": 0,
                "size": 16,
            },
            currentPage: 1,

            goodsType: [
                // { name: '全部', id: 0, icon: 'https://qiniu.youjiamall.cn/xcicon3.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon03.png' },
                 { name: '单品', id: 1, icon: 'https://qiniu.youjiamall.cn/xsicon4.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon04.png' }, { name: '组合品', id: 2, icon: 'https://qiniu.youjiamall.cn/tupianxs.png', selIcon: 'https://qiniu.youjiamall.cn/tupianxs1.png' }],
            ordCurrent: 0,
            typeCurrent: 0,


            advanced: true,
            dataSource: [],
            selectedRows: [],
            pagination: {
                current: 1,
                pageSize: 12,
                total: 0
            },
            total: 0,
            current: 1,
            visible: false,
            confirmLoading: false,

            // 新建方案弹窗
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },

            prodList: [],

            groupList: [],

            selectVisible: false,//加入方案弹窗
            checkedGroup: "",//组id
            delVisible: false,//确认删除弹窗

            // 排序参数
            groupParams: {
                "page": 0,
                "size": 100,
                "createTimeOrderBy": "asc",
                "updateTimeOrderBy": "",
                "marketPriceOrderBy": "",
                "profitMarginOrderBy": ""
            },

            classfiyData: {},
            progDetail: {},
            loading: true,
            loadingMore: false,
            showLoadingMore: false,
            addCurrentIndex: 0,//全局组合数量
            specsList: [],//规格列表
            checkList: [],//规格id
            prodSelectList: [],
            planName: "方案",//方案名称
            currentProjectData: { type: 1, count: 1 },
            selectCurrentProjectData: {},

            delIndex: -1,
            //当前选中的规格是第几条，清空勾选使用
            selProdIndex: -1,

            delGroupIdList: [],//删除组的id
            planId: "",//方案id
            goodsVisible: false,//商品详情
            listType: 0,

            currentCartData: {},//当前购物车数据
            cartVisible: false,//打开购物车
            currentGoodsDetail: {},//商品详情
            goodsDetailId: "",//商品id
            openCheckGroup: false,//打开更换组合
            currentCheckGroupInfo: {},//更换组合当前选中的要更换的数据
            recycleList: [{ editNeedOrderPlanGroupDetailParamList: [] }],//回收站列表
            isSelectFormBatchId: "",//详情批次id
            currentReloadGoodsBatchId: "",//当前要恢复的数据，
            isSelectFormData: {},//组合品详情
            selectFormType: 0, //选择弹窗 详情1
            initPath: "",
            isAllopen: false,//全部展开
        }
    },
    authorize: {
        deleteRecord: 'delete'
    },
    mounted() {
        this.initPath = this.$route.path;
        // this.getData();//查询列表
        // 编辑
        if (this.$route.query.planId) {
            console.log(1)
            this.planId = this.$route.query.planId;
            this.planName = this.$route.query.planName
            this.getGroupList(0);//查询组
        } else {
            console.log(2)
            this.planId = localStorage.getItem('planId');
            if (this.planId) {
                this.planName = localStorage.getItem('planName');
                this.getGroupList(0);//查询组
            }
        }
        this.getData();
        console.log(this.planId, 'this.planId')
        if (this.$route.query.subType == 101 && !this.planId) {
            // 初始请求参数
            this.addCombination()
            this.loading = false;
            // this.groupList = [{ name: '组合', value: 0, costPrice: 0, profit: 0, profitRate: 0, open: true, editNeedOrderPlanGroupDetailParamList: [] }]
        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        },
    },
    beforeDestroy() {
        this.closePage()
    },
    methods: {
        // 计算价格
        getCostPrice(index) {
            // 检查 groupList 是否存在，并且指定的 index 是否有效
            if (!this.groupList || !this.groupList[index]) {
                return; // 如果 groupList 或指定的 index 无效，直接返回
            }

            // 确保 editNeedOrderPlanGroupDetailParamList 存在且是数组类型
            let paramList = this.groupList[index].editNeedOrderPlanGroupDetailParamList;
            // 如果 paramList 不是数组，给它一个空数组作为默认值
            if (!Array.isArray(paramList)) {
                paramList = [];
            }
            // 如果 paramList 为空，则直接返回
            if (paramList.length === 0 && this.groupList[index].costPrice == 0) {
                return 0;
            }
            if (this.groupList[index].costPrice != 0 && paramList.length === 0) {
                return this.groupList[index].costPrice;
            }

            let price = 0;

            // 使用 reduce 进行分组
            const grouped = paramList.reduce((acc, item) => {
                if (item.type == 2) {
                    // 对于 type == 2，按 goodsSpecBatchId 分组，只取一个 packagePrice
                    const key = `type2-${item.goodsSpecBatchId}`;
                    if (!acc[key]) {
                        acc[key] = { priceToAdd: item.packagePrice }; // 只取一个 packagePrice
                    }
                } else if (item.type == 1) {
                    // 对于 type == 1，按 goodsSpecBatchId 分组，取 goodsSpecCount * platformDiscountPrice 最大的值
                    const key = `type1-${item.goodsSpecBatchId}`;
                    const currentPrice = item.platformDiscountPrice * item.goodsSpecCount;
                    if (!acc[key] || acc[key].priceToAdd < currentPrice) {
                        acc[key] = { priceToAdd: currentPrice }; // 取最大值
                    }
                }
                return acc;
            }, {});

            // 计算最终的总价
            Object.values(grouped).forEach(item => {
                price += item.priceToAdd || 0; // 累加 priceToAdd
            });

            // 保证价格保留两位小数
            price = parseFloat(price.toFixed(2));

            // 将价格存入 groupList 中
            this.groupList[index].costPrice = price;
            return price;
            // // 检查 groupList 是否存在，并且指定的 index 是否有效
            // if (!this.groupList || !this.groupList[index]) {
            //     return;  // 如果 groupList 或指定的 index 无效，直接返回
            // }
            // // 确保 editNeedOrderPlanGroupDetailParamList 存在且是数组类型
            // let paramList = this.groupList[index].editNeedOrderPlanGroupDetailParamList;

            // // 如果 paramList 不是数组，给它一个空数组作为默认值
            // if (!Array.isArray(paramList)) {
            //     paramList = [];
            // }

            // // 如果 paramList 为空，则直接返回
            // if (paramList.length === 0 && this.groupList[index].costPrice == 0) {
            //     return 0;
            // }
            // if (this.groupList[index].costPrice != 0 && paramList.length === 0) {
            //     return this.groupList[index].costPrice
            // }

            // let price = 0;
            // // 使用 reduce 进行分组并取每个组中 platformDiscountPrice 最大的项
            // const grouped = paramList.reduce((acc, item) => {
            //     const key = `${item.goodsSpecBatchId}-${item.specGoodsId}`;  // 使用组合键进行分组
            //     if (!acc[key]) {
            //         acc[key] = item;  // 如果该组还没有项，则直接加入
            //     } else {
            //         // // 比较并保留 platformDiscountPrice 最大的项
            //         // if (acc[key].platformDiscountPrice < item.platformDiscountPrice) {
            //         //     acc[key] = item;
            //         // }
            //         if ((acc[key].platformDiscountPrice * acc[key].goodsSpecCount) < (item.platformDiscountPrice * item.goodsSpecCount)) {
            //             acc[key] = item;
            //         }
            //     }
            //     return acc;
            // }, {});

            // // 计算最终的总价
            // Object.values(grouped).forEach(item => {
            //     price += item.platformDiscountPrice * item.goodsSpecCount;
            // });

            // // 保证价格保留两位小数
            // price = parseFloat(price.toFixed(2));

            // // 将价格存入 groupList 中
            // this.groupList[index].costPrice = price;
            // return price;
        },
        // 关闭选择弹窗
        closeSelectForm() {
            this.selectVisible = false;
        },
        // 商品还原
        reloadGoods(index, goodsIndex) {
            if (this.$route.query.subType == 101) {
                let _that = this;
                this.$confirm({
                    title: '您确认要恢复该商品吗',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _that.checkedGroup = _that.groupList[0].id
                        _that.currentReloadGoodsBatchId = _that.recycleList[index].goodsList[goodsIndex].goodsSpecBatchId
                        _that.handleCheckGroup()
                    }
                });

            } else {
                this.openCheckGroup = true;
                this.currentReloadGoodsBatchId = this.recycleList[index].goodsList[goodsIndex].goodsSpecBatchId
            }

        },
        closeAllGroup() {
            // 打印当前状态，方便调试
            console.log('当前是否全部展开:', this.isAllopen);

            if (!this.isAllopen) {
                // 全部收起
                console.log('收起所有项');
                this.groupList.forEach(item => {
                    item.open = false;  // 设置每一项为收起状态
                });
                this.isAllopen = true;  // 修改收起状态
            } else {
                // 全部展开
                console.log('展开所有项');
                this.groupList.forEach((item, index) => {
                    item.open = true;  // 设置每一项为展开状态
                    this.getProgDetail(index);  // 调用详情请求
                });
                this.isAllopen = false;  // 修改展开状态
            }
        },
        // // 全部收起
        // closeAllGroup() {
        //     // 有展开
        //     if (!this.isAllopen) {
        //         // 全部收起
        //         this.groupList.forEach(item => {
        //             item.open = false;
        //         })
        //         this.isAllopen = true;
        //     } else {
        //         // 全部展开
        //         this.groupList.forEach((item, index) => {
        //             item.open = true;
        //             this.getProgDetail(index)
        //         })
        //         this.isAllopen = false;
        //     }
        // },
        // 关闭商品详情弹窗
        goodsDetailCancel() {
            this.goodsVisible = false
            this.goodsDetailId = ""
            this.currentGoodsDetail = {}
        },
        // 打开购物车
        openShopCart(item) {
            this.cartVisible = true;
            this.currentCartData = item;
        },
        //切换组合/回收站 
        changeGroupType(type) {
            if (type == 1) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/v2/recycleList', 'post', { page: 0, size: 1500, needOrderPlanId: this.planId }).then(res => {
                    console.log(res, 'res=')
                    this.recycleList = [{ editNeedOrderPlanGroupDetailParamList: [...res.data.data] }]
                    this.recycleList.forEach(item => {
                        if (!item.goodsList) {
                            this.$set(item, 'goodsList', []);
                        }
                        item.goodsList = (Array.isArray(item.editNeedOrderPlanGroupDetailParamList) ?
                            item.editNeedOrderPlanGroupDetailParamList : []
                        ).reduce((result, item) => {
                            // 查找是否已经存在该batchId的组
                            let group = result.find(g => g.goodsSpecBatchId === item.goodsSpecBatchId);

                            // 如果该batchId的组不存在，创建新的组
                            if (!group) {
                                group = { goodsSpecBatchId: item.goodsSpecBatchId, goodsName: item.name, type: item.type, spuList: [] };
                                result.push(group);
                            }

                            // 查找是否已经存在相同skuId的组
                            let spuGroup = group.spuList.find(s => s.goodsId === item.specGoodsId);

                            // 如果该skuId的组不存在，创建新的skuGroup
                            if (!spuGroup) {
                                spuGroup = {
                                    goodsId: item.specGoodsId,
                                    goodsImagePath: item.specGoodsImagePath,
                                    goodsName: item.specGoodsName,
                                    packagePrice: item.packagePrice,
                                    goodsSpecCount: item.goodsSpecCount,
                                    skuList: []
                                };
                                group.spuList.push(spuGroup);
                            }
                            // 将当前对象添加到相应skuId的skusList中
                            spuGroup.skuList.push(item);
                            // 对skuList进行排序，按照platformDiscountPrice降序排列
                            spuGroup.skuList.sort((a, b) => b.platformDiscountPrice - a.platformDiscountPrice);

                            return result;
                        }, []);
                    });
                    console.log(this.recycleList, 'this.recycleList')
                })
            } else {
                this.getGroupList(type)
            }
        },
        // 组合上下移动
        changeGroupAction(type, index) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/updateOrderNum/' + this.planId + '/' + this.groupList[index].id + '/' + type, 'post', {}).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('移动成功！')
                    this.getGroupList(0, index)
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 商品操作
        changeAction(type, index, goodsIndex) {
            if (type == 'del') {
                this.deleteGoods(index, goodsIndex)
                return;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/updateOrderNum/' + this.groupList[index].id + '/' + this.groupList[index].goodsList[goodsIndex].goodsSpecBatchId + '/' + type, 'post', {}).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('移动成功！')
                    this.getGroupList(0, index)
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        handleCheckGroupCancel() {
            this.openCheckGroup = false;
            this.currentReloadGoodsBatchId = ""
            this.checkedGroup = ""
            this.currentCartData = {}
        },
        handleCheckGroup() {
            if (!this.checkedGroup) {
                this.$message.warning('请选择组合');
                return false;
            }
            if (this.listType == 1) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/recover', 'post', {
                    toPlanGroupId: this.checkedGroup,
                    goodsSpecBatchId: this.currentReloadGoodsBatchId
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('商品恢复成功！')
                        this.openCheckGroup = false
                        this.currentReloadGoodsBatchId = ""
                        this.checkedGroup = ""
                        this.getData()
                        this.changeGroupType(1)
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
                return;
            }
            if (!this.currentCheckGroupInfo.goodsSpecBatchId || !this.currentCheckGroupInfo.oldGroupId) {
                this.$message.warning('选择数据有误');
                return false;
            }
            let newIndex = this.groupList.findIndex(item => item.id === this.checkedGroup);
            // 如果未找到对应组合，直接返回
            if (newIndex === -1) {
                this.$message.warning('未找到对应的组合');
                return false;
            }
            const params = {
                fromPlanGroupId: this.currentCheckGroupInfo.oldGroupId,
                toPlanGroupId: this.checkedGroup,
                goodsSpecBatchId: this.currentCheckGroupInfo.goodsSpecBatchId,
            };
            // 发起组合更换请求
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/move', 'post', params).then(res => {
                if (res.data.code === 200) {
                    this.$message.success('组合更换成功！');
                    this.openCheckGroup = false;
                    this.currentCheckGroupInfo = {};
                    this.currentReloadGoodsBatchId = ""

                    // 获取更新后的组合列表
                    return request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/page', 'post', {
                        page: 0,
                        size: 1500,
                        planId: this.planId,
                        needOrderDetailId: this.$route.query.needOrderDetailId,
                        del: 0,
                    });
                } else {
                    this.$message.error(res.data.message);
                    return Promise.reject('组合更换失败');
                }
            }).then(res => {
                if (res.data.code === 200) {
                    let data = res.data.data;
                    // 更新组合列表，标记打开的组合
                    data.data.forEach((item, idx) => {
                        item.open = (idx === newIndex || idx === this.currentCheckGroupInfo.groupIndex);
                    });
                    this.groupList = data.data;
                    this.loading = false;
                    // 如果 currentCheckGroupInfo.groupIndex 是有效的，加载它的详情
                    if (this.currentCheckGroupInfo.groupIndex !== undefined) {
                        this.getProgDetail(this.currentCheckGroupInfo.groupIndex);
                    }
                    // 始终加载新组合的详情
                    this.getProgDetail(newIndex);
                } else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(error => {
                    console.error('请求失败:', error);
                });
        },
        // 更换组合
        checkGroup(index, goodsIndex) {
            this.checkedGroup = ""
            this.currentCheckGroupInfo = { goodsSpecBatchId: this.groupList[index].goodsList[goodsIndex].goodsSpecBatchId, oldGroupId: this.groupList[index].id, groupIndex: index }
            this.openCheckGroup = true;

            // console.log(index)
        },
        // 查看已加入商品详情
        changeEdit(index, goodsIndex) {
            this.selectVisible = true;

            if (this.groupList[index].goodsList[goodsIndex].type == 1) {
                this.selectFormType = 1;
                this.isSelectFormBatchId = this.groupList[index].goodsList[goodsIndex].goodsSpecBatchId;
            } else {
                this.selectFormType = 2;
                // 组合品
                this.isSelectFormData = this.groupList[index].goodsList[goodsIndex];
            }
        },
        // 打开商品详情
        goodsDetail(data) {
            this.goodsDetailId = data.goodsId;
            this.currentGoodsDetail = data;
            this.goodsVisible = true;
        },
        // 关闭弹窗
        addProjectCancel() {
            this.selectVisible = false;
            this.isSelectFormBatchId = ""
            this.selectFormType = 0;
            this.isSelectFormData = {}
        },
        // 切换规格查看信息
        goodsSpecChange(index, i) {
            this.prodList[index].idx = i;
            this.$set(this.prodList, index, { ...this.prodList[index], idx: i });
        },
        openH(url) {
            window.open(url)
        },
        onFocus(e) {
            this.strNumber = e.target.value
        },
        editGoodsNumber(index, id, goodsIndex) {
            if (this.$route.query.planStatus == 10) {
                let isSuccess = false;
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/update', 'post', {
                    "planGroupId": id,
                    "goodsSpecId": this.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex].goodsSpecId,
                    goodsSpecCount: this.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex].goodsSpecCount
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('数量更新成功！')
                        isSuccess = true;
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
                setTimeout(() => {
                    if (!isSuccess) {
                        this.$set(this.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex], 'goodsSpecCount', this.strNumber);
                    }
                }, 700)
            }
            if (!this.groupList[index].editNeedOrderPlanGroupDetailParamList) return;
            if (!this.groupList[index].editNeedOrderPlanGroupDetailParamList) {
                return 0;
            }
            let price = 0;
            this.groupList[index].editNeedOrderPlanGroupDetailParamList.forEach(item => {
                price = price + item.platformDiscountPrice * item.goodsSpecCount
            })
            return price

        },
        // 修改方案名
        editName() {
            if (!this.planName) {
                this.$message.warning('请输入方案名称！')
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/update', 'post', {
                "id": this.planId,
                "name": this.planName
            }).then(res => {
                if (res.data.code == 200) {
                    if (localStorage.getItem('planName')) {
                        localStorage.setItem('planName', this.planName)
                    }
                    this.$message.success('已修改方案名称！')
                } else {
                    this.$message.error(res.data.message)
                }
            })

        },
        // 计算名称
        editGroupName(index) {
            if (!this.planId) {
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/update', 'post', {
                planId: this.planId,
                planGroupId: this.groupList[index].id,
                name: this.groupList[index].name

            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('组合名称修改成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        // 计算利润率
        getProfit(index) {
            let price = this.getCostPrice(index);
            if (this.$route.query.marketPrice && price) {
                return ((this.$route.query.marketPrice - price) / this.$route.query.marketPrice * 100).toFixed(2);
            } else {
                return 0;
            }
        },
        // 展开收起
        checkOpen(index) {
            this.groupList[index].open = !this.groupList[index].open
            if (this.planId) {
                this.getProgDetail(index)
            }
        },
        closePage() {
            console.log(this.$route.path, 'this.$route.path')
            if (this.$route.path != this.initPath) {
                localStorage.removeItem('planId');
                localStorage.removeItem('planName');
            }

            // EventBus.$emit('itemRemoved');
        },
        // 添加组合
        async addCombination() {
            console.log('addCombination')
            try {
                // 初始请求参数
                let params = {
                    name: this.planName
                };
                if (this.planId) {
                    params.id = this.planId;
                }
                // this.addcurrentindex = this.groupList.length;
                // 更新计划
                const planResponse = await request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/update', 'post', params);
                if (planResponse.data.code == 200) {
                    if (!this.planId) {
                        this.planId = planResponse.data.data;
                        localStorage.setItem('planId', planResponse.data.data);
                        localStorage.setItem('planName', this.planName);
                    }
                    if (this.$route.query.subType != 101) {
                        this.$message.success('创建成功！');
                    }
                    // 更新组合信息
                    const groupResponse = await request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/save', 'post', {
                        planId: this.planId,
                        needOrderDetailId: this.$route.query.needOrderDetailId,
                        name: '组合' + (this.groupList.length + 1),
                        // name: '组合',
                        costPrice: 0,
                        profit: 0,
                        profitRate: 0,
                    });
                    if (groupResponse.data.code === 200) {
                        // 更新 groupList
                        this.groupList.unshift({
                            needOrderDetailId: this.$route.query.needOrderDetailId,
                            name: '组合' + (this.groupList.length + 1),
                            id: groupResponse.data.data,
                            costPrice: 0,
                            profit: 0,
                            profitRate: 0,
                            editNeedOrderPlanGroupDetailParamList: [],
                            open: true
                        });
                    } else {
                        this.$message.error(groupResponse.data.message);
                    }
                } else {
                    this.$message.error(planResponse.data.message);
                }
            } catch (error) {
                this.$message.error('发生错误，请稍后再试');
            }
        },
        selectChange(e) {
            if (e.target.checked) {
                this.delGroupIdList.push(e.target.value);
            } else {
                console.log(this.delGroupIdList, 'this.delGroupIdList')
                this.delGroupIdList.forEach((item, index) => {
                    if (item == e.target.value) {
                        this.delGroupIdList.splice(index, 1);
                    }
                })
            }
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },

        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
            return result
        },
        // 筛选
        filterTypeChange(idx) {
            this.$message.loading();
            this.queryData.type = this.goodsType[idx].id
            this.getData();
            this.typeCurrent = idx;
        },
        // 生成方案
        groupSave() {
            if (this.groupList.length == 0) {
                this.$message.warning('方案组合不可为空')
                return false;
            }
            if (!this.planName) {
                this.$message.warning('请输入方案名称！')
                return false;
            }

            let params = {
                "needOrderId": this.$route.query.needId, //需求单id
                "needOrderDetailId": this.$route.query.needOrderDetailId, //子需求单id
                "name": this.planName, //方案名称
                "editNeedOrderPlanGroupParamList": this.groupList
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/save', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功！')
                    EventBus.$emit('itemRemoved');
                    this.$router.go(-1);
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 删除单个商品
        deleteGoods(index, goodsIndex) {
            let _that = this;
            this.$confirm({
                title: '您确定要删除吗？(如删除组合品商品，组合品下相关的商品全部删除)',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    // 编辑走借口删除
                    let params = {
                        planGroupId: _that.groupList[index].id,
                        goodsSpecBatchId: _that.groupList[index].goodsList[goodsIndex].goodsSpecBatchId,
                        del: 1, //删除1 恢复0
                    }
                    request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/v2/delete', 'post', params).then(res => {
                        if (res.data.code == 200) {
                            _that.$message.success('删除成功！')
                            _that.groupList[index].editNeedOrderPlanGroupDetailParamList = _that.groupList[index].editNeedOrderPlanGroupDetailParamList.filter(item => item.goodsSpecBatchId !== _that.groupList[index].goodsList[goodsIndex].goodsSpecBatchId);
                            if (goodsIndex >= 0 && goodsIndex < _that.groupList[index].goodsList.length) {
                                _that.groupList[index].goodsList.splice(goodsIndex, 1);
                                _that.getData();
                                // _that.getCostPrice(index);
                                // _that.getProgDetail(index);//查详情 
                            }
                            // _that.groupList[index].goodsList.splice(goodsIndex, 1);
                            // _that.groupList[index].goodsList[goodsIndex]
                            _that.getGroupList(0, index)

                        } else {
                            _that.$message.error(res.data.message)
                        }
                    })
                }
            });
        },
        // 底部按钮删除
        delConfirm() {
            console.log(this.delGroupIdList.length, 'this.delGroupIdList')
            if (this.delGroupIdList.length > 0) {
                let that = this;
                this.$confirm({
                    title: '您确定要删除吗？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        // 编辑删除  走接口
                        that.delGroup()
                        // if (that.$route.query.planStatus == 10) {
                        // } else {
                        //     // 新增删除
                        //     that.delGroupIdList.forEach(item => {
                        //         that.groupList.forEach((it, index) => {
                        //             if (item == it.id) {
                        //                 that.groupList.splice(index, 1)
                        //             }
                        //         })
                        //     })

                        // }
                    }
                });
            } else {
                if (this.$route.query.subType == 101) {
                    this.$message.warning('请选择要删除的方案！')
                } else {
                    this.$message.warning('请选择要删除的组合！')
                }
            }

        },
        // 删除组
        delGroup() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/delete', 'post', {
                planId: this.planId,
                planGroupIdList: this.delGroupIdList,
                needOrderDetailId: this.$route.query.needOrderDetailId
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('删除成功！')
                    this.getGroupList(0);
                    this.getProgDetail();
                }
            })
        },
        // 加入组合 组合品
        addProjectConfirmProd(data) {
            // 判断是否选中了组合
            if (!data.checkedGroup && this.$route.query.subType != 101) {
                this.$message.warning('请选择组合');
                return;
            }
            const { goodsSpecVoList } = this.currentProjectData;
            const timestamp = new Date().getTime();
            // 创建一个新的数组，用于存放复制的对象
            let newGoodsSpecList = [];
            // 复制每个对象，根据 count 值来重复添加
            for (let i = 0; i < data.count; i++) {
                goodsSpecVoList.forEach(item => {
                    // 创建一个新的对象，并添加一个唯一的 goodsSpecBatchId
                    newGoodsSpecList.push({
                        ...item, // 保留原有的属性
                        packagePrice:this.currentProjectData.price,
                        platformDiscountPrice: item.price,
                        goodsSpecBatchId: timestamp + i, // 根据 i 来设置不同的 batchId
                    });
                });
            }
            // 判断当前加入到哪个组合
            let selIndex = -1;
            let planGroupId = "";
            if (this.$route.query.subType == 101) {
                // 货堆默认第一个
                selIndex = 0;
                planGroupId = this.groupList[0].id
            } else {
                planGroupId = data.checkedGroup
                this.groupList.forEach((item, idx) => {
                    if (item.id == data.checkedGroup) {
                        selIndex = idx;
                    }
                })
            }

            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/v2/save', 'post', {
                planGroupId: planGroupId,
                goodsId: this.currentProjectData.goodsId,
                goodsSpecInfoVoList: newGoodsSpecList,
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('添加成功！')
                    // 传入查询哪个组合
                    this.getProgDetail(selIndex)
                    this.getData()
                } else {
                    this.$message.error(res.data.message);
                }
            })
            this.selectVisible = false;
        },
        // 加入组合 单品
        addProjectConfirm(data) {
            this.checkList = data.checkList;
            this.checkedGroup = data.checkedGroup;
            // 平铺还是重叠
            let oneType = data.type;
            if (this.checkList.length == 0) {
                this.$message.warning('请选择规格');
                return;
            }
            if (!this.checkedGroup) {
                this.$message.warning('请选择组合');
                return;
            }
            let goodsSpecVoList = []
            this.groupList.forEach((item, idx) => {
                if (!item.goodsList) {
                    this.$set(item, 'goodsList', []); // 确保 goodsList 是响应式的
                }
                if (item.id == this.checkedGroup) {
                    if (!this.groupList[idx].open) {
                        this.$set(this.groupList[idx], 'open', true);
                    }
                    let sonSelectList = []
                    sonSelectList = this.currentProjectData.goodsSpecVoList.filter(goods => {
                        return data.checkListInfo.some(item => item.goodsSpecId === goods.goodsSpecId);
                    });
                    // 平铺
                    if (oneType == 1) {
                        let selectList = [];
                        sonSelectList.forEach((goods) => {
                            const isInCheckList = data.checkListInfo.some(item => item.goodsSpecId === goods.goodsSpecId);

                            if (isInCheckList) {
                                const checkItem = data.checkListInfo.find(item => item.goodsSpecId === goods.goodsSpecId);
                                console.log(checkItem, 'checkItem');
                                // 根据 count 值生成副本 (count - 1) 个副本
                                const copiesToGenerate = checkItem.count - 1;  // 生成副本数是 count - 1
                                // 为源数据生成批次 ID
                                const originalGoodsSpecBatchId = Math.random().toString(36).substring(2, 15);
                                // 先加入源商品，带上批次 ID
                                const originalGoods = {
                                    ...goods, // 保留原始属性
                                    platformDiscountPrice: goods.price, // 设置优惠价格
                                    goodsSpecBatchId: originalGoodsSpecBatchId // 为源数据生成批次 ID
                                };
                                selectList.push(originalGoods);
                                // 生成副本
                                for (let i = 0; i < copiesToGenerate; i++) {
                                    const goodsSpecBatchId = Math.random().toString(36).substring(2, 15); // 为副本生成新的批次 ID
                                    const newGoods = {
                                        ...goods, // 保留原始属性
                                        platformDiscountPrice: goods.price, // 设置优惠价格
                                        goodsSpecBatchId: goodsSpecBatchId // 新的副本批次 ID
                                    };
                                    console.log(newGoods, 'newGoods');
                                    selectList.push(newGoods); // 将副本加入列表
                                }
                            } else {
                                // 如果不在 checkList 中，直接添加原商品
                                const originalGoodsSpecBatchId = Math.random().toString(36).substring(2, 15); // 为源数据生成批次 ID
                                const originalGoods = {
                                    ...goods, // 保留原始属性
                                    platformDiscountPrice: goods.price, // 设置优惠价格
                                    goodsSpecBatchId: originalGoodsSpecBatchId // 为源数据生成批次 ID
                                };
                                selectList.push(originalGoods); // 直接加入原商品
                            }
                        });
                        goodsSpecVoList = [...selectList]; // 最终生成包含原商品和副本的列表
                    } else {
                        // 合并多规格
                        sonSelectList.forEach((goods, index) => {
                            if (data.checkList.includes(goods.goodsSpecId)) {
                                goods.platformDiscountPrice = goods.price;
                            } else {
                                sonSelectList.splice(index, 1);
                            }
                        });
                        console.log(sonSelectList, 'sonSelectList')
                        // 将数据复制多份，每份批次ID不同
                        goodsSpecVoList = this.duplicateObjects(sonSelectList, data.count)
                    }
                    request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/v2/save', 'post', {
                        //方案组id
                        planGroupId: data.checkedGroup,
                        //商品id
                        goodsId: this.currentProjectData.goodsId,
                        goodsSpecInfoVoList: goodsSpecVoList,
                    }).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success('添加成功！');
                            this.getProgDetail(idx)
                            this.getData()
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })

                }
            });
            this.selectVisible = false;
        },
        // 将数据复制多份，每份批次ID不同
        duplicateObjects(arr, num) {
            let result = [];
            for (let i = 0; i < num; i++) {
                // 生成随机的批次 ID
                const goodsSpecBatchId = Math.random().toString(36).substr(2, 9);  // 生成一个随机的字符串
                arr.forEach(item => {
                    result.push({
                        ...item,  // 复制原有对象
                        goodsSpecBatchId: goodsSpecBatchId  // 添加随机的批次 ID
                    });
                });
            }
            return result;
        },
        // 将商品加入到组合
        addGoodsJoin() {
            let groupIndex = this.groupList.findIndex((item) => item.id == this.checkedGroup)
            // 筛选选中的组合
            let selectedGroup = this.groupList.filter((item) => item.id == this.checkedGroup)[0];
            let goodsList = selectedGroup.editNeedOrderPlanGroupDetailParamList || []
            selectedGroup.editNeedOrderPlanGroupDetailParamList = [...this.currentProjectData.goodsSpecVoList, ...goodsList];
            this.groupList.splice(groupIndex, 1, selectedGroup)
        },
        // 货堆 加入组合
        addProjectConfirmHD() {
            if (this.checkList.length == 0) {
                this.$message.warning('请选择规格');
                return;
            }
            for (let item of this.groupList[0].editNeedOrderPlanGroupDetailParamList) {
                if (this.checkList.some(it => it === item.goodsSpecId)) {
                    this.$message.error('当前选择的规格已在该组合中，可直接增加规格数量');
                    return;  // 终止整个外部循环
                }
            }
            // 传id和优加底价
            let arr = []
            this.currentProjectData.goodsSpecVoList.forEach(item => {
                this.checkList.forEach(it => {
                    if (it == item.goodsSpecId) {
                        item.name = this.currentProjectData.name
                        item.imagePath = this.currentProjectData.imagePath
                        item.platformDiscountPrice = item.price
                        item.goodsId = this.currentProjectData.goodsId
                        item.goodsSpecCount = item.goodsSpecCount ? item.goodsSpecCount : 1
                        item.type = this.currentProjectData.type
                        arr.push({
                            ...item,
                        })
                    }
                })
            })
            console.log(this.currentProjectData, 'this.currentProjectDatathis.currentProjectDatathis.currentProjectData')
            // 编辑添加直接走接口
            if (this.$route.query.planStatus == 10) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/v2/save', 'post', {
                    planGroupId: this.groupList[0].id,//方案组id
                    goodsId: this.currentProjectData.goodsId,//商品id
                    goodsSpecInfoVoList: arr,
                }).then(res => {
                    if (res.data.code == 200) {
                        let groupIndex = 0
                        // 筛选选中的组合
                        let selectedGroup = this.groupList[0];
                        let goodsList = selectedGroup.editNeedOrderPlanGroupDetailParamList || []
                        selectedGroup.editNeedOrderPlanGroupDetailParamList = [...arr, ...goodsList];
                        this.groupList.splice(groupIndex, 1, selectedGroup)
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            } else {
                let groupIndex = 0
                // 筛选选中的组合
                let selectedGroup = this.groupList[0];
                let goodsList = selectedGroup.editNeedOrderPlanGroupDetailParamList || []
                selectedGroup.editNeedOrderPlanGroupDetailParamList = [...arr, ...goodsList];
                this.groupList.splice(groupIndex, 1, selectedGroup)
            }
            this.selectVisible = false;
        },
        // 查询方案规格
        popSelectChange(e) {
            this.checkedGroup = e.target.value;
        },
        // 规格 单选单品
        specsSelectChange(idx, e) {
            this.prodList[idx].checkList = []
            this.checkList = e;
            this.prodList[idx].checkList = e
            this.selProdIndex = idx;
        },
        // 选中加入的组合
        specsSelectJoinChange(idx, e) {
            console.log(idx, e, 'ccc')
        },
        // getGroupList(type, index) {
        //     let oldGroupList = [];
        //     if (this.groupList.length > 0) {
        //         oldGroupList = JSON.parse(JSON.stringify(this.groupList));
        //     }
        //     request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/page', 'post', {
        //         page: 0,
        //         size: 1500,
        //         planId: this.planId,
        //         needOrderDetailId: this.$route.query.needOrderDetailId,
        //         del: type
        //     }).then(res => {
        //         if (res.data.code == 200) {
        //             let data = res.data.data;
        //             // 创建一个Promise数组
        //             let promises = [];

        //             data.data.forEach((item, idx) => {
        //                 if (oldGroupList.length > 0) {
        //                     oldGroupList.forEach(oldItem => {
        //                         if (oldItem.id == item.id) {
        //                             item.open = oldItem.open;
        //                             // 将请求加入Promise数组
        //                             promises.push(this.getProgDetail(idx));  // 查详情
        //                         }
        //                     });
        //                 }
        //                 // 如果传入了index，也需要做一次请求
        //                 if (index === idx) {
        //                     item.open = true;
        //                     promises.push(this.getProgDetail(index)); // 查详情
        //                 }
        //             });

        //             this.loading = false;
        //             this.groupList = data.data;
        //             // 等待所有的请求都完成
        //             Promise.all(promises).then(() => {
        //                 console.log('所有请求已完成');
        //             }).catch((err) => {
        //                 console.error('请求失败', err);
        //             });
        //         }
        //     });
        // },
        // 查询组列表
        getGroupList(type, index) {
            // let oldGroupList = []
            // if(this.groupList.length > 0){
            //     oldGroupList = JSON.parse(JSON.stringify(this.groupList))
            // }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/page', 'post', { page: 0, size: 1500, planId: this.planId, needOrderDetailId: this.$route.query.needOrderDetailId, del: type }).then(res => {
                if (res.data.code == 200) {
                    let data = res.data.data;
                    data.data.forEach((item, idx) => {
                        // if(oldGroupList.length > 0) {
                        //     oldGroupList.forEach(oldItem=>{
                        //         if(oldItem.id == item.id) {
                        //             item.open = oldItem.open
                        //             this.getProgDetail(idx);//查详情 
                        //         }
                        //     })
                        // }
                        if (index == idx) {
                            item.open = true;
                        }
                    })
                    this.loading = false;
                    this.groupList = data.data;
                    if (index) {
                        this.getProgDetail(index);//查详情 
                    } else {
                        this.getProgDetail(-1);//查详情 
                    }
                }
            })
        },
        // 查询方案详情
        getProgDetail(index) {
            let id = ""
            if (index == -1) {
                id = this.groupList[0].id;
            } else {
                id = this.groupList[index].id;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/v2/list/' + id, 'post', {}).then(res => {
                if (index == -1) {
                    this.$set(this.groupList[0], 'editNeedOrderPlanGroupDetailParamList', res.data.data);
                    this.$set(this.groupList[0], 'open', true);
                    // this.addCurrentIndex = this.groupList.length;

                } else {
                    this.$set(this.groupList[index], 'editNeedOrderPlanGroupDetailParamList', res.data.data);
                    console.log(this.groupList, 'list')
                }
                this.groupList.forEach(item => {
                    if (!item.goodsList) {
                        this.$set(item, 'goodsList', []);
                    }
                    item.goodsList = (Array.isArray(item.editNeedOrderPlanGroupDetailParamList) ?
                        item.editNeedOrderPlanGroupDetailParamList : []
                    ).reduce((result, goodsItem) => {
                        // 查找是否已经存在该batchId的组
                        let group = result.find(g => g.goodsSpecBatchId === goodsItem.goodsSpecBatchId);

                        // 如果该batchId的组不存在，创建新的组
                        if (!group) {
                            group = { goodsSpecBatchId: goodsItem.goodsSpecBatchId, goodsName: goodsItem.name, type: goodsItem.type, spuList: [] };
                            result.push(group);
                        }

                        // 查找是否已经存在相同skuId的组
                        let spuGroup = group.spuList.find(s => s.goodsId === goodsItem.specGoodsId);
                        // 如果该skuId的组不存在，创建新的skuGroup
                        if (!spuGroup) {
                            spuGroup = {
                                goodsId: goodsItem.specGoodsId,
                                goodsImagePath: goodsItem.specGoodsImagePath,
                                goodsName: goodsItem.specGoodsName,
                                goodsSpecCount: goodsItem.goodsSpecCount,
                                packagePrice: goodsItem.packagePrice,
                                packagePlfPrice: goodsItem.packagePlfPrice,
                                skuList: []
                            };
                            group.spuList.push(spuGroup);
                        }
                        // 将当前对象添加到相应skuId的skusList中
                        spuGroup.skuList.push(goodsItem);
                        spuGroup.skuList.forEach(sku => {
                            spuGroup.goodsStatus = 1;
                            if(sku.goodsStatus == 2) {
                                spuGroup.goodsStatus = sku.goodsStatus;
                            }
                            spuGroup.specGoodsStatus = 1;
                            if(sku.specGoodsStatus == 2) {
                                spuGroup.specGoodsStatus = sku.specGoodsStatus;
                            }
                            if(sku.goodsSpecPriceChange == 1) {
                                // 规格底价变化 1.变化 2.无变化
                                spuGroup.goodsSpecPriceChange = 1;
                            }
                            if(sku.goodsSpecStatus == 2) {
                                // 单品组合品 1上架 2下架
                                spuGroup.goodsSpecStatus = 2;
                            }
                            
                        })
                        // 对skuList进行排序，按照platformDiscountPrice降序排列
                        spuGroup.skuList.sort((a, b) => (b.platformDiscountPrice * b.goodsSpecCount) - (a.platformDiscountPrice * a.goodsSpecCount));
                        // spuGroup.skuList.sort((a, b) => b.platformDiscountPrice - a.platformDiscountPrice);
                        result.forEach(resItem=>{
                            resItem.spuList.forEach(resSpuItem=>{
                                resItem.goodsStatus = 1;
                                resItem.specGoodsStatus = 1;
                                console.log(resSpuItem,'resSpuItem')
                                if(resSpuItem.goodsStatus == 2) {
                                    resItem.goodsStatus = 2;
                                }
                                if(resSpuItem.specGoodsStatus == 2) {
                                    resItem.specGoodsStatus = 2;
                                }
                                if(resSpuItem.goodsSpecStatus == 2) {
                                    resItem.goodsSpecStatus = 2;
                                }
                            })
                        })
                        return result;
                    }, []);
                });
                console.log(this.groupList,'this.groupList')
            })
        },
        // 创建/加入到组
        createProg(item, type) {
            this.checkList = []
            // 货堆
            if (type == 0) {
                // 组合品 || 单品单规格 直接调用加入方案
                // if (item.type == 2 || (item.type == 1 && item.goodsSpecVoList.length == 1)) {
                //     if (item.type == 2) {
                //         const hasStatus2 = item.goodsSpecVoList.some(it => it.status === 2);
                //         if (hasStatus2) {
                //             this.$message.error('组合品所包含的商品，有任意一款已经下架，则无法加入方案')
                //             return false
                //         }
                //     } else {
                //         const hasStatus2 = item.goodsSpecVoList.some(it => it.status === 2);
                //         if (hasStatus2) {
                //             this.$message.error('该规格已经下架')
                //             return false
                //         }
                //     }
                //     if (item.goodsSpecVoList.length > 0) {
                //         item.goodsSpecVoList.forEach(it => {
                //             this.checkList.push(it.goodsSpecId)
                //         })
                //         this.currentProjectData = item;
                //         this.specsList = item.goodsSpecVoList
                //         this.addProjectConfirmHD()
                //     }
                // } else {
                this.currentProjectData = item;
                this.specsList = item.goodsSpecVoList
                this.selectVisible = true;
                this.checkedGroup = ""
                // }
            } else {
                // 提货券/宅配
                if (this.groupList.length > 0) {
                    if (item.type == 2 || (item.type == 1 && item.goodsSpecVoList.length == 1)) {
                        if (item.type == 2) {
                            const hasStatus2 = item.goodsSpecVoList.some(it => it.status === 2);
                            if (hasStatus2) {
                                this.$message.error('组合品所包含的商品，有任意一款已经下架，则无法加入方案')
                                return false
                            }
                        } else {
                            const hasStatus2 = item.goodsSpecVoList.some(it => it.status === 2);
                            if (hasStatus2) {
                                this.$message.error('该规格已经下架')
                                return false
                            }
                        }

                        if (item.goodsSpecVoList.length > 0) {
                            item.goodsSpecVoList.forEach(it => {
                                this.checkList.push(it.goodsSpecId)
                            })
                            this.currentProjectData = item;
                            this.currentProjectData.count = 1;
                            this.specsList = item.goodsSpecVoList
                            this.selectVisible = true;
                            this.checkedGroup = ""
                        }
                    } else {
                        console.log(item, 'item')
                        this.currentProjectData = item;

                        this.specsList = item.goodsSpecVoList
                        this.selectVisible = true;
                        this.checkedGroup = ""
                    }
                    console.log(this.currentProjectData, 'this.currentProjectData')
                } else {
                    this.$message.warn('请先创建组合')
                }
                return;
            }
        },
        searchChange() {
            this.queryData.classifyIdList = this.threeId
            this.getData();

        },
        resetChange() {
            this.queryData = {
                type:this.queryData.type,
                "page": 0,
                "size": 16,
            }
            this.currentPage = 1
            // this.ordCurrent = 0;
            // this.typeCurrent = 0;
            this.searchChange()
        },
        // 左右切换图片
        prevImg(index) {
            // 获取最后一张图片
            let imgs = JSON.parse(JSON.stringify(this.prodList[index].picList[this.prodList[index].picList.length - 1]));
            // 删除数组最后一个元素
            this.prodList[index].picList.splice(this.prodList[index].picList.length - 1, 1);
            // 将图片插入到数组最前面
            this.prodList[index].picList.unshift(imgs);
            // 使用 Vue.set 强制更新数组，确保响应式
            this.$set(this.prodList, index, {
                ...this.prodList[index],
                picList: [...this.prodList[index].picList]
            });
        },
        nextImg(index) {
            let imgs = JSON.parse(JSON.stringify(this.prodList[index].picList[0]));
            this.prodList[index].picList.splice(0, 1);
            this.prodList[index].picList.push(imgs);
            // 使用 Vue.set 强制更新数组
            this.$set(this.prodList, index, {
                ...this.prodList[index],
                picList: [...this.prodList[index].picList]
            });
        },
        // 切换规格
        checkSpecImsg(index, i) {
            this.$set(this.prodList, index, { ...this.prodList[index], idx: i });
        },
        getData() {
            this.queryData.programmeId = this.$route.query.id;
            this.queryData.needOrderPlanId = this.planId

            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/v2/goods/list', 'post', this.queryData).then(res => {
                let data = res.data.data;
                this.$message.destroy()
                if (res.data.code == 200) {
                    this.prodList = data.data;
                    this.prodList.forEach(item => {
                        // 用来存储结果的数组
                        const result = [];
                        // 遍历原始数据
                        item.goodsSpecVoList.forEach(it => {
                            // 初始化sku加入数量
                            it.count = 1;
                            // 查找 result 中是否已经有该 goodsId 的对象
                            const existing = result.find(group => group.goodsId === it.specGoodsId);
                            if (existing) {
                                // 如果找到，直接将当前 item 加入 skuList
                                existing.skuList.push(it);
                            } else {
                                // 如果没有找到，创建一个新的对象
                                result.push({
                                    goodsId: it.specGoodsId,
                                    goodsName: it.specGoodsName,
                                    goodsImagePath: it.specGoodsImagePath,
                                    goodsSpecCount: it.goodsSpecCount,
                                    // packagePriceChange: it.packagePriceChange,//组合品底价变化 1.变化 2.无变化
                                    skuList: [it]
                                });
                            }
                        });
                        item.newGoodsSpecVoList = result
                        item.picList = [];
                        item.goodsSpecVoList.forEach(spec => {
                            item.picList.push(spec.goodsSpecImagePath)
                        })
                        item.idx = 0;
                        if (item.type == 2) {
                            if (item.goodsSpecVoList.length > 0) {
                                item.checkList = [];
                                item.goodsSpecVoList.forEach(it => {
                                    item.checkList.push(it.goodsSpecId); // 将 goodsSpecId 添加到 checkList
                                });
                            }
                        }

                    });
                    console.log(this.prodList, 'this.prodList')
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

.search input {
    padding-left: 10px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}


::v-deep .page-content {
    padding: 0 !important;
}

.page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}



.left-top {
    position: relative;
    padding: 0 0px 20px;
    box-sizing: border-box;
    min-width: 375px;
    max-width: 375px;
    border-right: 1px solid #f3f3f3;
    overflow-y: auto;
    scrollbar-width: thin;
}

.items-right {
    min-width: 876px;
    box-sizing: border-box;
}

.items-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #010101;
    margin: 10px 0;
}

.items-list {
    list-style: none;
    padding: 10px;
    box-sizing: border-box;
    background: #E8ECF9;
    border-radius: 8px;
}

.items-list li {
    display: flex;
    width: 100%;
    margin-bottom: 4px;
}


.items-list span {
    color: #5542F6;
}

.select-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 15px;
    box-sizing: border-box;
}

.select-nav>p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-nav img {
    width: 10px;
    height: 14px;
    margin-left: 6px;
    vertical-align: middle;
}

.select-nav p {
    cursor: pointer;
}

.select-nav p {
    width: 20%;
    text-align: center;
    background: #F7F9FA;
    border-radius: 20px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #93989A;
    padding: 6px 0px;
}


.prog-tit {
    width: 100%;
    height: 63px;
    background: rgba(98, 113, 235, .15);
    padding: 0 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010101;
    justify-content: space-between;
    // margin-bottom: 8px;
}

.prog-tit div {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5542F6;
}

.prog-list {
    padding: 0 15px;
    box-sizing: border-box;
    min-height: 150px;
}

.prog-list li {
    border-radius: 8px;
    border: 1px solid #EBEAED;
    margin-bottom: 8px;
}

.prog-ls-top {
    position: relative;
    padding: 4px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEAED;
}

.prog-ls-top a {
    display: inline-block;
    width: 16px;
    background: #E8ECF9;
    height: 16px;
    margin-right: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
}

.prog-ls-top img {
    margin-right: 12px;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    border: 1px solid #EBEAED;
}

.ls-top-tit {
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #0D0A19;
    text-align: left;
    line-height: 15px;
}

.prog-ls-btm {
    color: #0D0A19;
    padding: 5px 8px;
    box-sizing: border-box;
    font-size: .8rem;
}

.prog-ls-btm p {
    margin-bottom: 4px;
}

.prog-ls-btm span {
    color: #84818A;
}


.prog-btm {
    position: fixed;
    z-index: 9;
    min-width: 375px;
    bottom: 0;
    background: #eee;
    padding: 10px;
    font-size: 13px;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;

}

.prog-btm button {
    width: 35%;
    height: 36px;
    border-radius: 8px;
}


.items-right {
    height: calc(100vh - 95px);
    overflow-y: scroll;
    padding: 8px;
    box-sizing: border-box;
}

.items-right ul {
    display: flex;
    flex-wrap: wrap;
}

.items-right li {
    position: relative;
    width: 200px;
    height: 351px;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid #E4E6E8;
    overflow: hidden;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
}

.items-right .rig-img-box {
    display: flex;
    margin: auto;
}

.rig-img-box img {
    width: 70px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #ABABAB;
    height: 70px;
    object-fit: contain;
    margin-right: 8px;
}

::v-deep .ant-card-bordered {
    border: none !important;
}

.ant-card-bordered {
    border: none !important;
}

.items-ls-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    border: none !important;
}

.items-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
}

.items-btn button {
    width: 60px;
    height: 29px;
    margin-right: 8px;
    font-size: 13px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    border-radius: 8px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #5542F6;
    border-radius: 4px;
}

.items-btn button:nth-child(1) {
    border: 1px solid #5542F6;
    color: #5542F6;
}

.items-btn button:nth-child(2) {
    margin-right: 0;
    background: #5542F6;
    color: #fff;
}

.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 6px;
}

.items-empty {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ant-checkbox-group {
    width: 100%;
}



.select-list {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 4px 0;
}

.select-list img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.select-list ul {
    display: flex;
}

.select-list li {
    cursor: pointer;
    width: 93px;
    height: 36px;
    border-radius: 8px;
    background: #F7F9FA;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #93989A;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.select-list .active-sel {
    background: #5542F6;
    color: #fff;
}



.text-wrap1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-wrap2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.text-wrap3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.close-icon {
    position: absolute;
    right: 10px;
    top: 18px;
    cursor: pointer;
}

.items-tag {
    position: absolute;
    left: 0;
    top: 0;
    width: 44px;
    height: 20px;
    background: #5542F6;
    border-radius: 3px 0px 8px 0px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #fff;
}

.active {
    color: blue;
}

.text-danger {
    color: red;
}




::v-deep .ant-card-body {
    padding: 0;
}


.page {
    overflow: hidden;
}


.prog-mai-item {
    width: 100%;
}


.redCard {
    border: 1px solid red !important;
    position: relative;
    overflow: hidden;

}

.goods-tips {
    position: absolute;
    right: 0;
    top: 0;
    width: 28px;
    text-align: center;
    background: red;
    font-size: 12px;
    border-radius: 0px 0px 0px 10px;
    color: #fff;
}

.selText {
    color: #5542f6;
}



.swiper-container {
    width: 100%;
    height: 35px;
    margin-top: 10px;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 5px 0 0;
    font-size: 16px;
    width: 35px !important;
}

.slide-item {
    text-align: center;
    // padding: 10px;
    font-size: 16px;
}

.swiper-button-next,
.swiper-button-prev {
    color: #000;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    font-size: 12px;
    padding: 10px;
}

::v-deep .swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 12px;
}



.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 760px) {
    .swiper-button-next {
        right: 20px;
        transform: rotate(90deg);
    }

    .swiper-button-prev {
        left: 20px;
        transform: rotate(90deg);
    }
}


.items-info {
    background: rgba(247, 247, 247, .79);
    border-radius: 4px;
    height: 168px;
    margin-top: 8px;
}

.items-info .items-title {
    padding: 8px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #0D0A19;
    height: 47px;
    border-bottom: 1px solid #E8E8E8;
    box-sizing: border-box;
    margin: 0;
}

.items-info-btm {
    box-sizing: border-box;
    padding: 8px;
}


.items-info-btm>div {
    display: flex;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #84818A;
}

.items-info-btm>div p {

    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #0D0A19;
}





.goodsimg {
    position: relative;
    width: 35px;
    height: 35px;
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #5542F6;
    margin-right: 4px;
}

.goodsimg span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 12px;
    background: rgba(0, 0, 0, .6);
    text-align: center;
    color: #fff;
    line-height: 12px;
    font-size: 12px;
}

.items-zh-info-top {
    display: flex;
}

.items-zh-wrap {
    height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.shop-num {
    position: absolute;
    top: -5px;
    right: -7px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: red;
    color: #fff;
    text-align: center;
    line-height: 20px;
    transform: scale(.8);
}

.items-zh-info-tag {
    display: flex;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #0D0A19;
    height: 27px;
    line-height: 27px;
    border-radius: 4px;
    background: rgba(247, 247, 247, .8);
}

.items-zh-info-tag span {
    width: 50px;
    color: #5542F6;
}


.imgActive {
    border: 1px solid #5542F6 !important;
}

.prog-goodsName {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #0D0A19;
}


.df {
    display: flex;
}

.left-goods {
    margin-bottom: 14px;
    margin-top: 10px;
}

.left-goods>p,
.left-goods img {
    width: 88px;
    height: 88px;
    background: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;

}



.prog-goods-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #0D0A19;
}

.price-text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 13px;
    color: #FF0000;
}




.left-goods-p {
    position: relative;
}

.left-goods-p span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 18px;
    background: rgba(0, 0, 0, .6);
    text-align: center;
    color: #fff;
    line-height: 18px;
    font-size: 12px;
}



.prog-goods-action {
    display: flex;
    justify-content: space-between;
}

.goods-action img {
    width: 100%;
    height: 100%;
}

.goods-action div {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    border-radius: 2px;
    text-align: center;
    line-height: 20px;
}

.goods-action p {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 2px;
    text-align: center;
    line-height: 20px;
}


.group-tips {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0 8px 0 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}



/* // 弹窗header */
.modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
}

.modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}

::v-deep .ant-modal-body {
    padding: 0;
}

.cart-list {
    display: flex;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #0D0A19;
    border-bottom: 1px dashed #E7E7E7;
    padding: 10px 0px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.cart-box span,
.cart-list span {
    color: red;
}

.cart-box {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #0D0A19;
}

.noBorder {
    border: none !important;
}

a {
    cursor: pointer;
}


.left-goods-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600 !important;
    font-size: 13px !important;
    color: #0D0A19 !important;
}

::v-deep .items-btn .ant-btn {
    border: none;
}





.remind span {
    border-radius: 3px 0px 8px 0px;
    display: inline-block;
    height: 20px;
    padding: 0 4px;
    line-height: 20px;
    font-size: 12px !important;
    background: red;
    border-radius: 4px;
    color: #fff;
    position: relative !important;
}
</style>